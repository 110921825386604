import { CURRENT_ENVIRONMENT } from '@environment/variables';
import { DatadogConfig } from '@shared/entities/common/models/datadog';

export const WALLET_CONNECT_ZENKIPAY_PROJECT_ID =
  '08417f7ca5d61d357e48fcd3713b6a69';
export const DATADOG_CONFIG: DatadogConfig = {
  applicationId: '6b0db1bc-daf1-497d-a840-1b7e85458148',
  clientToken: 'pub2b544351e5c0f5ae39426a02f2459a91',
  service: `modal-zenkipay-${CURRENT_ENVIRONMENT}`,
  sessionReplaySampleRate: 20,
};
export const BINANCE_PROD_PAYMENT = false;
