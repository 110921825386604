export interface HttpResponseError {
  status: number;
  statusText: string;
  url: string;
  ok: false;
  name: string;
  message: string;
  error: {
    title: string;
    category: string;
    status: string;
    errorCode: number;
    requestId: string;
    body: {
      stackTraceElement: null;
      errorCause: string;
      errorMessage: string;
    };
    timestamp: number;
    humanMessage: string;
    personId: string;
    username: string;
    validationErrors: null;
  };
}
