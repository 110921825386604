<form
  id="close-payment-options"
  class="zenkipay-page"
  [formGroup]="form"
  (submit)="submit()"
>
  <section class="zenkipay-page-header">
    <pbw-pay-test-payment-banner></pbw-pay-test-payment-banner>
    <pbw-pay-header></pbw-pay-header>
  </section>
  <section class="zenkipay-page-body">
    <div
      for="options"
      class="instructions"
      [innerHTML]="'close_payment.instructions' | translate"
    ></div>
    <mat-radio-group id="options" formControlName="option">
      <mat-radio-button
        [value]="CLOSE_PAYMENT_OPTIONS.ALREADY_PAID_AND_CLOSE"
        [class.active]="
          optionSelected === CLOSE_PAYMENT_OPTIONS.ALREADY_PAID_AND_CLOSE
        "
      >
        <div class="option">
          <span
            class="option-description"
            [innerHTML]="
              'close_payment.options.' +
                CLOSE_PAYMENT_OPTIONS.ALREADY_PAID_AND_CLOSE
                | lowercase
                | translate
            "
          ></span>
          <div class="zenkibot">
            <img
              class="zenkibot-img"
              src="assets/global/img/zenkibot-option-email.svg"
              alt=""
            />
          </div>
        </div>
      </mat-radio-button>
      <mat-radio-button
        [value]="CLOSE_PAYMENT_OPTIONS.AWAITING_HERE"
        [class.active]="optionSelected === CLOSE_PAYMENT_OPTIONS.AWAITING_HERE"
      >
        <div class="option">
          <span
            class="option-description"
            [innerHTML]="
              'close_payment.options.' + CLOSE_PAYMENT_OPTIONS.AWAITING_HERE
                | lowercase
                | translate
            "
          ></span>
          <div class="zenkibot">
            <img
              class="zenkibot-img"
              src="assets/global/img/zenkibot-option-qr.svg"
              alt=""
            />
          </div>
        </div>
      </mat-radio-button>
      <mat-radio-button
        [value]="CLOSE_PAYMENT_OPTIONS.CANCEL_AND_CLOSE"
        [class.active]="
          optionSelected === CLOSE_PAYMENT_OPTIONS.CANCEL_AND_CLOSE
        "
      >
        <div class="option">
          <span
            class="option-description"
            [innerHTML]="
              'close_payment.options.' + CLOSE_PAYMENT_OPTIONS.CANCEL_AND_CLOSE
                | lowercase
                | translate
            "
          ></span>
          <div class="zenkibot">
            <img
              class="zenkibot-img"
              src="assets/global/img/zenkibot-option-cancel.svg"
              alt=""
            />
          </div>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </section>
  <section class="zenkipay-page-footer">
    <article class="button__container">
      <button
        id="back"
        type="button"
        title="back"
        mat-stroked-button
        color="primary"
        (click)="back()"
        [innerHTML]="'buttons.back' | translate"
      ></button>
      <button
        id="confirm"
        type="submit"
        title="confirm"
        mat-flat-button
        color="primary"
        [disabled]="form.invalid"
        [innerHTML]="'buttons.confirm' | translate"
      ></button>
    </article>
  </section>
  <section class="zenkipay-app-version">v{{ appVersion }}</section>
</form>
