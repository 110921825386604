import { Provider } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DatadogConfig } from '@shared/entities/common/models/datadog';

import { DATADOG_CONFIG } from '../../../constants/providers';
import { isRunningInLocalhost } from '../../../functions/window';
import {
  DatadogLocalhostService,
  DatadogMonitorService,
  DatadogService,
} from '../services';

function initializeDatadogStrategies(
  document: Document,
  config: DatadogConfig
): DatadogService {
  if (isRunningInLocalhost(document)) {
    return new DatadogLocalhostService();
  }
  const datadogService: DatadogService = new DatadogMonitorService(config);
  datadogService.startSessionReplayRecording();
  return datadogService;
}

export const DatadogStrategyProvider: Provider = {
  provide: DatadogService,
  useFactory: initializeDatadogStrategies,
  deps: [DOCUMENT, DATADOG_CONFIG],
};
