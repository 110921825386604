import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { MerchantService } from '../../../modules/merchant/services';

@Component({
  selector: 'pbw-pay-test-payment-banner',
  templateUrl: './test-payment-banner.component.html',
  styleUrls: ['./test-payment-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestPaymentBannerComponent {
  public readonly isTestPayment$: Observable<boolean>;

  constructor(merchantService: MerchantService) {
    this.isTestPayment$ = merchantService.isTestPayment$;
  }
}
