import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';

import { ClosePaymentOptionsComponent } from '../components';
import { CLOSE_PAYMENT_OPTIONS } from '../enums';

@Injectable({ providedIn: 'root' })
export class ClosePaymentOptionsService {
  private readonly _overlayRef!: OverlayRef;

  private _component?: ComponentRef<ClosePaymentOptionsComponent>;

  constructor(overlay: Overlay) {
    this._overlayRef = overlay.create();
  }

  public showCloseOptions(): Observable<CLOSE_PAYMENT_OPTIONS> {
    if (this._component) return of(CLOSE_PAYMENT_OPTIONS.NONE);
    const optionsOverlayPortal: ComponentPortal<ClosePaymentOptionsComponent> =
      new ComponentPortal<ClosePaymentOptionsComponent>(
        ClosePaymentOptionsComponent
      );
    this._component = this._overlayRef.attach(optionsOverlayPortal);
    return this._component.instance.optionSelected$.pipe(
      tap((): void => {
        this._overlayRef.detach();
        this._component = undefined;
      })
    );
  }

  public detach(): void {
    this._component && this._overlayRef.detach();
    this._component = undefined;
  }
}
