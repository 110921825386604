import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { HttpPaymentSignatureInterceptor } from '../../interceptors/http-payment-signature';

export const HttpPaymentSignatureInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpPaymentSignatureInterceptor,
  multi: true,
};
