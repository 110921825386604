import { options } from '@shared/common/constants';
import { MerchantSetting } from '@shared/entities/modules/merchant/models/merchant-setting';
import { ObjectSchema } from 'joi';
import * as Joi from 'joi';

const merchantSettingsSchema: ObjectSchema<MerchantSetting> =
  Joi.object<MerchantSetting>().keys({
    hasSettingWebhook: Joi.boolean().required(),
    url: Joi.string().allow(null).optional(),
  });

export async function validateMerchantSettings(
  merchantSetting: MerchantSetting
): Promise<MerchantSetting> {
  return merchantSettingsSchema.validateAsync(merchantSetting, options);
}
