import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSelectModule } from '@shared/angular/modules/i18n/components/language-select';
import { CURRENCY_FORMAT } from '@shared/common/constants';

import { TooltipModule } from '../../components/tooltip';
import { Discount } from '@shared/entities/modules/coupon';

@Component({
  standalone: true,
  selector: 'ui-kit-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule, TooltipModule, LanguageSelectModule],
})
export class HeaderComponent {
  public readonly CURRENCY_FORMAT = CURRENCY_FORMAT;
  public readonly ZENKIPAY_LOGO = 'assets/global/img/logo-pay.svg';
  public readonly ZENKIPAY_LOGO_TEST = 'assets/global/img/logo-pay-test.svg';

  @Input() public showBigLogo!: boolean;
  @Input() public showAmount!: boolean;
  @Input() public amount!: number | string;
  @Input() public currency!: string;
  @Input() public finalAmount!: number | string;
  @Input() public isTestPayment!: boolean | null;
  @Input() public isDiscountActive?: boolean;
  @Input() public isDiscountChanged?: boolean;
  @Input() public showDiscount?: boolean;
  @Input() public discount?: Discount | null;
}
