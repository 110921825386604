export interface Merchant {
  merchantName: string;
  currency: string | null;
  merchantId: string;
  merchantUnitId: string;
  discountPercentage: number;
  amountToPay: number | string | null;
  merchantPlugin: MerchantPlugin;
  partners?: MerchantPartner[] | null;
  activeCampaignPromotion: boolean;
  cryptoPaymentEnabled: boolean;
}

export interface MerchantPlugin {
  merchantPluginId: string;
  pluginKey: string;
  status: string;
  environment?: MERCHANT_PLUGIN_ENVIRONMENT;
  integrationType: string;
  pluginSecurityIntegrationMode: string;
  merchantId: string;
  merchantUnitId: string;
  merchantName: string;
  merchantSiteUrl: string;
  live?: boolean;
}

export interface MerchantPartner {
  accountId: string;
  accountOwnerId: string;
  defaultAsset?: string;
  partnerId: string;
  partnerCode: string;
  status: MERCHANT_PARTNER_STATUS;
  partnerExternalId: number | string;
}

export enum MERCHANT_PLUGIN_ENVIRONMENT {
  PROD = 'PROD',
  TEST = 'TEST',
}

export enum MERCHANT_PARTNER_STATUS {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}
