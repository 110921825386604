import { NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InternationalizationModule } from '@shared/angular/modules/i18n';
import { ServiceWorkerConfigModule } from '@shared/angular/modules/service-worker';
import { LanguageInterceptorProvider } from '@shared/angular/modules/i18n/providers/language.interceptor';
import { LanguageInitializerProvider } from '@shared/angular/modules/i18n/providers/language.initializer';
import { AuthStrategyProvider } from '@shared/angular/providers/auth.strategy';
import { AuthInterceptorProvider } from '@shared/angular/providers/auth.interceptor';
import { CacheInterceptorProvider } from '@shared/angular/providers/cache.interceptor';
import { HttpErrorsHandlerInterceptorProvider } from '@shared/angular/modules/http-errors-handler/providers';
import { HttpPaymentSignatureInterceptorProvider } from '@shared/angular/providers/http-payment-signature.interceptor';
import { ApiVersionProvider } from '@shared/angular/providers/api-version';
import { JwtProvider } from '@shared/angular/providers/jwt';
import { LoadingInterceptorProvider } from '@shared/angular/modules/loading/providers';
import { RequestRetryerInterceptorProvider } from '@shared/angular/providers/request-retryer.interceptor';
import { NavigatorProvider } from '@shared/angular/providers/navigator';
import { WindowProvider } from '@shared/angular/providers/window';
import { FriendlyErrorHandlerProvider } from '@core/pay/providers/friendly-error-handler.provider';
import { FriendlyViewParamsProvider } from '@core/pay/providers/friendly-view-params.provider';
import { CacheBusterInitializerProvider } from '@shared/angular/providers/cache-buster.initializer';
import {
  DatadogInterceptorProvider,
  DatadogStrategyProvider,
} from '@shared/angular/modules/datadog/providers';
import { RequestMetricsProvider } from '@shared/angular/providers/request-metrics.interceptor';
import { IS_NOT_PROD } from '@shared/common/constants';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { WalletConnectProjectIdProvider } from './providers/wallet-connect';
import { DatadogProvider } from './providers/datadog';
import { BinanceProdPaymentProvider } from './providers/binance-prod-payment';

const providers: Provider[] = [
  CacheBusterInitializerProvider,
  LanguageInitializerProvider,
  AuthStrategyProvider,
  AuthInterceptorProvider,
  CacheInterceptorProvider,
  HttpErrorsHandlerInterceptorProvider,
  HttpPaymentSignatureInterceptorProvider,
  ApiVersionProvider,
  JwtProvider,
  LoadingInterceptorProvider,
  RequestRetryerInterceptorProvider,
  LanguageInterceptorProvider,
  DatadogStrategyProvider,
  NavigatorProvider,
  WindowProvider,
  WalletConnectProjectIdProvider,
  DatadogProvider,
  DatadogInterceptorProvider,
  BinanceProdPaymentProvider,
  FriendlyErrorHandlerProvider,
  FriendlyViewParamsProvider,
];

/**
 * ? Below block is used to add only DEVELOPMENT providers.
 */
if (IS_NOT_PROD) {
  providers.push(RequestMetricsProvider);
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OverlayModule,
    AppRoutingModule,
    InternationalizationModule,
    ServiceWorkerConfigModule,
  ],
  providers,
})
export class AppModule {}
