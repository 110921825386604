import { HttpHeaders } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SECOND_IN_MS } from '@shared/common/constants';
import { Authorization } from '@shared/entities/common/models/authorization';
import {
  BehaviorSubject,
  filter,
  interval,
  map,
  Observable,
  Subscription,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';

import {
  AUTHORIZATION,
  CREDENTIALS,
  SkipAuthInterceptor,
} from '../../constants';
import { DecodedJWT } from '../../models';
import { SkipSpinnerInterceptor } from '../../modules/loading/constants';
import { BaseApiService } from '../base-api';
import { SessionStorageService } from '../storage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CredentialsType = any;

export abstract class AuthenticationService<Credentials = CredentialsType> {
  protected readonly _OFFSET_PERCENTAGE = 10;
  protected readonly _MIN_OFFSET_SECS = 10;
  protected readonly _MIN_OFFSET = 1000 * this._MIN_OFFSET_SECS;

  protected readonly _credentials$: BehaviorSubject<Credentials | null> =
    new BehaviorSubject<Credentials | null>(
      this._storageService.get<Credentials>(CREDENTIALS) || null
    );
  public readonly credentials$: Observable<Credentials | null> =
    this._credentials$.asObservable();

  public abstract get credentials(): Credentials | null;
  public abstract set credentials(credentials: Credentials | null);

  protected readonly _authorization$: BehaviorSubject<Authorization | null> =
    new BehaviorSubject<Authorization | null>(null);

  public readonly accessTokenDecoded$: Observable<DecodedJWT | null> =
    this._authorization$.pipe(
      map((authorization: Authorization | null): DecodedJWT | null => {
        if (!authorization) return null;
        return this._jwtService.decodeToken(authorization.access_token);
      })
    );

  protected _subscription?: Subscription;
  protected _authenticate$?: Subscription;

  constructor(
    protected readonly _zone: NgZone,
    protected readonly _jwtService: JwtHelperService,
    protected readonly _storageService: SessionStorageService,
    protected readonly _apiService: BaseApiService
  ) {}

  //#region PUBLIC

  public getAuthorization(): Observable<Authorization> {
    return this._authorization$.pipe(
      filter(Boolean),
      filter(({ access_token }: Authorization): boolean => {
        return !this._jwtService.isTokenExpired(
          access_token,
          this._MIN_OFFSET_SECS
        );
      }),
      take(1)
    );
  }

  public finish(): void {
    if (
      typeof this._subscription?.unsubscribe === 'function' &&
      !this._subscription.closed
    ) {
      this._subscription.unsubscribe();
    }
    this._subscription = undefined;
  }

  //#endregion PUBLIC

  //#region PROTECTED

  protected _init(): void {
    this._subscription = this._authorization$
      .pipe(
        filter(Boolean),
        switchMap((authorization: Authorization): Observable<string> => {
          return this._prepareDelay(authorization);
        }),
        switchMap((refreshToken: string): Observable<Authorization> => {
          return this._refreshAuthorization(refreshToken);
        })
      )
      .subscribe((authorization: Authorization): void => {
        this._storageService.set<Authorization>(AUTHORIZATION, authorization);
        this._authorization$.next(authorization);
      });
  }

  protected _prepareDelay({
    access_token,
    refresh_token,
  }: Authorization): Observable<string> {
    let enterRefresh = false;
    const expiration: Date | null =
      this._jwtService.getTokenExpirationDate(access_token);
    if (!expiration) {
      const msg = 'Access token expiration is null';
      return throwError((): Error => new Error(msg));
    }

    return this._zone
      .runOutsideAngular((): Observable<boolean> => {
        return interval(SECOND_IN_MS).pipe(
          map((): boolean => {
            const awaiting: number = this._calculateDelay(expiration.getTime());
            return awaiting <= 0 && !enterRefresh;
          }),
          filter(Boolean),
          tap((): void => {
            enterRefresh = true;
          })
        );
      })
      .pipe(map((): string => refresh_token));
  }

  protected _calculateDelay(expiration: number): number {
    const currentTime: number = Date.now();
    const expirationTime: number = expiration - currentTime;
    const offsetTime: number = Math.round(
      (this._OFFSET_PERCENTAGE * 100) / expirationTime
    );

    if (offsetTime < this._MIN_OFFSET) {
      return expirationTime - this._MIN_OFFSET;
    }
    return expirationTime - offsetTime;
  }

  protected _refreshAuthorization(
    refreshToken: string,
    additionalHeaders: Record<string, string> = {}
  ): Observable<Authorization> {
    const url = 'public/v2/merchants/plugin/refresh-token';
    const headers: HttpHeaders = new HttpHeaders({
      'X-Refresh-Token': refreshToken,
      [SkipSpinnerInterceptor]: '',
      [SkipAuthInterceptor]: '',
      ...additionalHeaders,
    });
    return this._apiService.get<Authorization>(url, { headers });
  }

  protected _unsubscribeAuthentication(): void {
    if (
      typeof this._authenticate$?.unsubscribe === 'function' &&
      !this._authenticate$.closed
    ) {
      this._authenticate$.unsubscribe();
    }
    this._authenticate$ = undefined;
  }

  //#endregion PROTECTED
}
