/* eslint-disable @typescript-eslint/no-empty-interface */
import { HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';

export interface BaseHttpBodyJsonOptions extends Options<'json', 'body'> {}
export interface BaseHttpBodyBufferOptions
  extends Options<'arraybuffer', 'response'> {}

interface Options<ResponseType, Observe> {
  headers?: HttpHeaders | Record<string, string | string[]>;
  context?: HttpContext;
  observe?: Observe;
  params?:
    | HttpParams
    | Record<
        string,
        string | number | boolean | ReadonlyArray<string | number | boolean>
      >;
  reportProgress?: boolean;
  responseType?: ResponseType;
  withCredentials?: boolean;
}
