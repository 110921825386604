import { Inject, Injectable } from '@angular/core';
import { API_VERSION } from '@shared/angular/constants';
import { VERSION } from '@shared/angular/enums/version';
import { AuthenticationService } from '@shared/angular/services/authentication';
import { BaseApiService } from '@shared/angular/services/base-api';
import { HttpResponseValidatorService } from '@shared/angular/services/http-response-validator';
import { SessionStorageService } from '@shared/angular/services/storage';
import { isEqual } from '@shared/common/functions/objects';
import { ZenkipayOptions } from '@shared/entities/script/v2/models';
import { MerchantSetting } from '@shared/entities/modules/merchant/models/merchant-setting';
import {
  Merchant,
  MerchantPartner,
  MerchantPlugin,
  MERCHANT_PARTNER_STATUS,
  MERCHANT_PLUGIN_ENVIRONMENT,
} from '@shared/entities/modules/merchant/models/merchant';
import {
  BehaviorSubject,
  tap,
  Observable,
  switchMap,
  distinctUntilChanged,
  map,
} from 'rxjs';

import {
  CRYPTO_LOVE_PERCENTAGE,
  IS_CRYPTO_LOVE_PERCENTAGE_CHANGED,
  MERCHANT,
  MERCHANT_SETTINGS,
} from '../constants/merchant';
import { validateMerchant, validateMerchantSettings } from '../validators';
import { Discount } from '@shared/entities/modules/coupon';
import { DISCOUNT_TYPE } from '@shared/entities/modules/coupon/enums/discount-type';

@Injectable({ providedIn: 'root' })
export class MerchantService {
  private readonly _merchant$: BehaviorSubject<Merchant | null>;
  public readonly merchant$: Observable<Merchant | null>;

  private readonly _merchantSettings: BehaviorSubject<MerchantSetting | null> =
    new BehaviorSubject<MerchantSetting | null>(null);
  public readonly merchantSettings$: Observable<MerchantSetting | null> =
    this._merchantSettings.asObservable();

  private readonly _discount$: BehaviorSubject<Discount | null> =
    new BehaviorSubject<Discount | null>(null);
  public readonly discount$: Observable<Discount | null> =
    this._discount$.asObservable();

  private readonly _isCryptoLovePercentageChanged$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public readonly isCryptoLovePercentageChanged$: Observable<boolean> =
    this._isCryptoLovePercentageChanged$.asObservable();

  private readonly _isTestPayment$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public readonly isTestPayment$: Observable<boolean> =
    this._isTestPayment$.asObservable();

  public readonly partners$: Observable<MerchantPartner[] | null>;
  public readonly activePartners$: Observable<MerchantPartner[] | null>;
  public readonly isActiveCampaignPromotion$: Observable<boolean>;

  private readonly _thereAreActivePartners$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public readonly thereAreActivePartners$: Observable<boolean> =
    this._thereAreActivePartners$.asObservable();

  public set thereAreActivePartners(active: boolean) {
    this._thereAreActivePartners$.next(active);
  }

  public readonly isCryptoPaymentEnabled$: Observable<boolean>;

  constructor(
    @Inject(API_VERSION) private readonly _apiVersion: VERSION,
    private readonly _apiService: BaseApiService,
    private readonly _storageService: SessionStorageService,
    private readonly _authService: AuthenticationService<ZenkipayOptions>,
    private readonly _httpResponseValidatorService: HttpResponseValidatorService
  ) {
    const merchant: Merchant | null = _storageService.get<Merchant>(MERCHANT);
    this._merchant$ = new BehaviorSubject<Merchant | null>(merchant);
    this.merchant$ = this._initializeMerchant$();
    merchant && this._setMerchant(merchant);
    this.partners$ = this._initializePartners$();
    this.activePartners$ = this._initializeActivePartners$();
    _apiVersion === VERSION.V2 && this._verifyIfIsTestPayment();
    this.isActiveCampaignPromotion$ = this._initializeActiveCampaignPromotion();
    this.isCryptoPaymentEnabled$ = this._initializeCryptoPaymentEnabled();
  }

  //#region PUBLIC

  public getMerchant(): Observable<Merchant> {
    const url = `${this._apiVersion}/merchants/plugin`;
    return this._apiService.get<Merchant>(url).pipe(
      tap((merchant: Merchant): void => this._setMerchant(merchant)),
      switchMap(
        this._httpResponseValidatorService.validate(
          validateMerchant(this._isTestPayment$.value),
          {
            method: 'GET',
            url,
          }
        )
      )
    );
  }

  public getMerchantSettings(
    merchantId: string,
    merchantUnitId: string
  ): Observable<MerchantSetting> {
    // * It keeps v1
    const apiVersion: VERSION = VERSION.V1;
    const url = `${apiVersion}/merchants/${merchantId}/units/${merchantUnitId}/plugins/me/webhooks`;
    return this._apiService.get<MerchantSetting>(url).pipe(
      tap((merchantSetting: MerchantSetting): void => {
        this._storageService.set<MerchantSetting>(
          MERCHANT_SETTINGS,
          merchantSetting
        );
        this._merchantSettings.next(merchantSetting);
      }),
      switchMap(
        this._httpResponseValidatorService.validate(validateMerchantSettings, {
          method: 'GET',
          url,
        })
      )
    );
  }

  public setCryptoLovePercentageInStore(
    cryptoLovePercentage: number | null
  ): void {
    const merchant: Merchant | null = this._merchant$.value;

    const isDiscountChanged =
      merchant?.discountPercentage != cryptoLovePercentage;

    this._storageService.set<boolean>(
      IS_CRYPTO_LOVE_PERCENTAGE_CHANGED,
      isDiscountChanged
    );
    this._isCryptoLovePercentageChanged$.next(isDiscountChanged);

    if (cryptoLovePercentage) {
      this._storageService.set<number>(
        CRYPTO_LOVE_PERCENTAGE,
        cryptoLovePercentage
      );
      const amountDisc: Discount = {
        valueDiscount: cryptoLovePercentage,
        discountType: DISCOUNT_TYPE.PERCENT,
      };
      this._discount$.next(amountDisc);
    }
  }

  public setAmountDiscount(amount: number, currency: string): void {
    const amountDisc: Discount = {
      valueDiscount: amount,
      currency: currency,
      discountType: DISCOUNT_TYPE.AMOUNT,
    };
    this._discount$.next(amountDisc);
  }

  public getCryptoLovePercent(): void {
    const merchant: Merchant | null = this._merchant$.value;
    if (merchant)
      this.setCryptoLovePercentageInStore(merchant.discountPercentage);
  }

  //#endregion PUBLIC

  //#region PRIVATE

  private _setMerchant(merchant: Merchant): void {
    this._storageService.set<Merchant>(MERCHANT, merchant);
    this._merchant$.next(merchant);

    if (this._apiVersion === VERSION.V1) {
      const { environment }: MerchantPlugin = merchant.merchantPlugin;
      const isTestPayment = environment !== MERCHANT_PLUGIN_ENVIRONMENT.PROD;
      this._isTestPayment$.next(isTestPayment);
    }

    this.setCryptoLovePercentageInStore(merchant.discountPercentage);
  }

  private _initializeMerchant$(): Observable<Merchant | null> {
    return this._merchant$.asObservable().pipe(distinctUntilChanged(isEqual));
  }

  private _verifyIfIsTestPayment(): void {
    if (this._apiVersion === VERSION.V1) return;
    const credentials: ZenkipayOptions | null = this._authService.credentials;
    const isTestPayment = !!credentials?.paymentSignature.startsWith('T');
    this._isTestPayment$.next(isTestPayment);
  }

  private _initializePartners$(): Observable<MerchantPartner[] | null> {
    return this.merchant$.pipe(
      map((merchant: Merchant | null): MerchantPartner[] | null => {
        if (!merchant) return null;
        return merchant.partners || [];
      })
    );
  }

  private _initializeActivePartners$(): Observable<MerchantPartner[] | null> {
    return this.partners$.pipe(
      map((partners: MerchantPartner[] | null): MerchantPartner[] | null => {
        if (!partners) return null;
        return partners.filter(({ status }: MerchantPartner): boolean => {
          return status === MERCHANT_PARTNER_STATUS.ACTIVE;
        });
      })
    );
  }

  private _initializeActiveCampaignPromotion(): Observable<boolean> {
    return this.merchant$.pipe(
      map((merchant: Merchant | null): boolean => {
        if (!merchant) return false;
        return merchant.activeCampaignPromotion || false;
      })
    );
  }

  private _initializeCryptoPaymentEnabled(): Observable<boolean> {
    return this.merchant$.pipe(
      map((merchant: Merchant | null): boolean => {
        if (!merchant) return false;
        return merchant.cryptoPaymentEnabled || false;
      })
    );
  }

  //#endregion PRIVATE
}
