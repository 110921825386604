import { options } from '@shared/common/constants';
import { PurchaseSummary } from '@shared/entities/script/models/zenkipay';
import { ObjectSchema } from 'joi';
import * as Joi from 'joi';

export const purchaseSummarySchema: ObjectSchema<PurchaseSummary> = Joi.object({
  currency: Joi.string().required(), // string;
  // totalItemsAmount: Joi.alternatives(Joi.number(), Joi.string())
  //   .allow(0)
  //   .required(),
  // shipmentAmount: Joi.alternatives(Joi.number(), Joi.string())
  //   .allow(0)
  //   .required(),
  // subtotalAmount: Joi.alternatives(Joi.number(), Joi.string())
  //   .allow(0)
  //   .required(),
  // taxesAmount: Joi.alternatives(Joi.number(), Joi.string()).allow(0).required(),
  // localTaxesAmount: Joi.alternatives(Joi.number(), Joi.string())
  //   .allow(0)
  //   .required(),
  // importCosts: Joi.alternatives(Joi.number(), Joi.string()).allow(0).required(),
  // discountAmount: Joi.alternatives(Joi.number(), Joi.string())
  //   .allow(0)
  //   .required(),
  grandTotalAmount: Joi.alternatives(Joi.number(), Joi.string()).required(),
  // additionalCharges
});

export async function validateOrder(
  purchaseSummary: PurchaseSummary
): Promise<PurchaseSummary> {
  return purchaseSummarySchema.validateAsync(purchaseSummary, options);
}
