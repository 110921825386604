import { options } from '@shared/common/constants';
import {
  Merchant,
  MerchantPartner,
  MerchantPlugin,
} from '@shared/entities/modules/merchant/models/merchant';
import { VERSION } from '@shared/angular/enums';
import { ObjectSchema } from 'joi';
import * as Joi from 'joi';

export function validateMerchant(
  isTestPayment: boolean
): (merchant: Merchant, apiVersion: VERSION) => Promise<Merchant> {
  return (merchant: Merchant, apiVersion: VERSION): Promise<Merchant> => {
    return getMerchantPluginSchema(isTestPayment, apiVersion).validateAsync(
      merchant,
      options
    );
  };
}

function getMerchantPluginSchema(
  isTestPayment: boolean,
  apiVersion: VERSION
): ObjectSchema<Merchant> {
  const merchantPluginSchema: ObjectSchema<MerchantPlugin> =
    Joi.object<MerchantPlugin>().keys({
      merchantPluginId: Joi.string().optional(),
      pluginKey: Joi.string().optional(),
      status: Joi.string().optional(),
      integrationType: Joi.string().optional(),
      pluginSecurityIntegrationMode: Joi.string().optional(),
      merchantId: Joi.string().optional(),
      merchantUnitId: Joi.string().optional(),
      merchantName: Joi.string().optional(),
      merchantSiteUrl: Joi.string().allow('', null).optional(),
      ...(apiVersion === VERSION.V1 && {
        environment: Joi.string().required(),
      }),
      live: Joi.boolean().required(),
    });

  const merchantPlatformSchema: ObjectSchema<MerchantPartner> =
    Joi.object<MerchantPartner>().keys({
      accountId: Joi.string().required(),
      accountOwnerId: Joi.string().required(),
      defaultAsset: isTestPayment
        ? Joi.string().optional().allow(null)
        : Joi.string().required(),
      partnerId: Joi.string().required(),
      partnerCode: Joi.string().required(),
      status: Joi.string().required(),
      partnerExternalId: Joi.alternatives()
        .try(Joi.number(), Joi.string())
        .required(),
    });

  const merchantSchema: ObjectSchema<Merchant> = Joi.object<Merchant>().keys({
    merchantName: Joi.string().required(),
    currency: Joi.string().allow('', null).optional(),
    merchantId: Joi.string().required(),
    merchantUnitId: Joi.string().required(),
    discountPercentage: Joi.number().allow(null).optional(),
    amountToPay: Joi.alternatives(Joi.number(), Joi.string())
      .allow(null)
      .optional(),
    merchantPlugin: merchantPluginSchema,
    partners: Joi.array().items(merchantPlatformSchema).optional().allow(null),
  });

  return merchantSchema;
}
