import { Injectable, NgZone } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Authorization } from '@shared/entities/common/models/authorization';
import { catchError, filter, Observable, switchMap, take } from 'rxjs';

import {
  AUTHORIZATION,
  ZENKIPAY_KEY,
  SkipAuthInterceptor,
  SkipAllHttpErrorInterceptor,
} from '../../constants';
import { SkipSpinnerInterceptor } from '../../modules/loading/constants';
import { BaseApiService } from '../base-api';
import { SessionStorageService } from '../storage';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'any' })
export class AuthenticationV1Service extends AuthenticationService<string> {
  public override get credentials(): string | null {
    return this._credentials$.value;
  }
  public override set credentials(zenkipayKey: string | null) {
    if (!zenkipayKey) return;
    this._storageService.set<string>(ZENKIPAY_KEY, zenkipayKey);
    this._credentials$.next(zenkipayKey);
    this._unsubscribeAuthentication();
    this._authenticate$ = this._authenticate().subscribe(
      (authorization: Authorization): void => {
        this._storageService.set<Authorization>(AUTHORIZATION, authorization);
        this._authorization$.next(authorization);
        this._unsubscribeAuthentication();
      }
    );
  }

  constructor(
    zone: NgZone,
    jwtService: JwtHelperService,
    storageService: SessionStorageService,
    apiService: BaseApiService
  ) {
    super(zone, jwtService, storageService, apiService);
    this._init();
  }

  //#region PROTECTED

  protected override _refreshAuthorization(
    refreshToken: string
  ): Observable<Authorization> {
    const additionalHeaders: Record<string, string> = {
      [SkipAllHttpErrorInterceptor]: '',
    };
    return super._refreshAuthorization(refreshToken, additionalHeaders).pipe(
      catchError((): Observable<Authorization> => {
        return this._authenticate({ [SkipSpinnerInterceptor]: '' });
      })
    );
  }

  //#endregion PROTECTED

  //#region PRIVATE

  private _authenticate(
    additionalHeaders: Record<string, string> = {}
  ): Observable<Authorization> {
    return this._credentials$.pipe(
      filter(Boolean),
      take(1),
      switchMap((zenkipayKey: string): Observable<Authorization> => {
        const url = 'public/v1/merchants/plugin/token';
        const headers: HttpHeaders = new HttpHeaders({
          [SkipAuthInterceptor]: '',
          ...additionalHeaders,
        });
        return this._apiService.post<Authorization, string>(url, zenkipayKey, {
          headers,
        });
      })
    );
  }

  //#endregion PRIVATE
}
