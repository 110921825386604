import { Provider } from '@angular/core';

import { VERSION } from '../../enums/version';
import { API_VERSION, WINDOW } from '../../constants/providers';

function initializeApiVersion(window: Window): string {
  const [, queryParams]: string[] = window.location.href.split('?');
  const params: URLSearchParams = new URLSearchParams(queryParams);

  const orderId: string | null = params.get('orderId');
  const paymentSignature: string | null = params.get('paymentSignature');
  const zenkipayKey: string | null = params.get('zenkipayKey');

  /** @version v2 */
  if (orderId && paymentSignature) return VERSION.V2;

  /** @version v1 */
  if (orderId && zenkipayKey) return VERSION.V1;

  return '';
}

export const ApiVersionProvider: Provider = {
  provide: API_VERSION,
  useFactory: initializeApiVersion,
  deps: [WINDOW],
};
