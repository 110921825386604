import { NgZone, ChangeDetectorRef, Directive, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SECOND_IN_MS } from '@shared/common/constants';
import {
  Subscription,
  Subject,
  Observable,
  interval,
  take,
  filter,
} from 'rxjs';

import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe';
import { HttpErrorsHandlerService } from '../../http-errors-handler/services';

@Directive()
@AutoUnsubscribe
export class BaseFriendlyErrorComponent implements OnInit {
  protected readonly _subscriptions: Subscription[] = [];

  protected readonly _DELAY_SECS = 15;
  public seconds: number | null = null;

  private readonly _close$: Subject<boolean> = new Subject<boolean>();
  public readonly close$: Observable<boolean> = this._close$.asObservable();

  private readonly _closeAll$: Subject<boolean> = new Subject<boolean>();
  public readonly closeAll$: Observable<boolean> =
    this._closeAll$.asObservable();

  constructor(
    protected readonly _ngZone: NgZone,
    protected readonly _cdr: ChangeDetectorRef,
    private readonly _httpErrorHandlerService: HttpErrorsHandlerService
  ) {}

  //#region LIFECYCLE

  public ngOnInit(): void {
    this._subscriptions.push(
      this._httpErrorHandlerService.error$
        .pipe(filter(Boolean))
        .subscribe((error: HttpErrorResponse): void => {
          console.error(
            '%c%s.%s[ERROR]: %o',
            'color: red; font-weight: bold',
            this.constructor.name,
            this.ngOnInit.name,
            error
          );
        })
    );
  }

  //#endregion LIFECYCLE

  //#region PUBLIC

  public back(): void {
    this._close$.next(true);
    this._closeAll$.next(false);
  }

  public close(): void {
    this._close$.next(false);
    this._closeAll$.next(true);
  }

  public addTimer(): void {
    this._subscriptions.push(this._handleTimerRedirectChanges());
  }

  //#endregion PUBLIC

  //#region PRIVATE

  private _handleTimerRedirectChanges(): Subscription {
    return this._ngZone.runOutsideAngular((): Subscription => {
      return interval(SECOND_IN_MS)
        .pipe(take(this._DELAY_SECS + 1))
        .subscribe((value: number): void => {
          this._ngZone.run((): void => {
            this.seconds = this._DELAY_SECS - value;
            if (!this.seconds) this.back();
            this._cdr.detectChanges();
          });
        });
    });
  }

  //#region PRIVATE
}
