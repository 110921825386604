import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ValidationError } from 'joi';

export type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface RequestDetails<Body = unknown> {
  method: Method;
  url: string;
  body?: Body;
  headers?: HttpHeaders;
  params?: HttpParams;
}

export interface HttpResponseValidatorErrorParams<
  Response = unknown,
  Body = unknown
> {
  error: ValidationError;
  method: Method;
  url: string;
  body?: Body;
  response?: Response;
  headers?: Record<string, string>;
  params?: Record<string, string>;
}

export class HttpResponseValidatorError<Response = unknown, Body = unknown>
  extends ValidationError
  implements HttpResponseValidatorErrorParams<Response, Body>
{
  public readonly error: ValidationError;
  public readonly method: Method;
  public readonly url: string;
  public readonly body?: Body;
  public readonly response?: Response;
  public readonly headers?: Record<string, string> | undefined;
  public readonly params?: Record<string, string> | undefined;

  constructor({
    error,
    method,
    url,
    body,
    response,
    headers,
    params,
  }: HttpResponseValidatorErrorParams) {
    super(error.message, error.details, error._original);
    this.error = error;
    this.method = method;
    this.url = url;
    this.response = response as Response;
    this.body = body as Body;
    this.headers = headers;
    this.params = params;
  }
}
