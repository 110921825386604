import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import {
  OverlayContainer,
  FullscreenOverlayContainer,
  OverlayModule,
} from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderModule } from '../../components/common/header';
import { TestPaymentBannerModule } from '../../components/common/test-payment-banner';
import { ClosePaymentOptionsComponent } from './components/close-payment-options.component';

@NgModule({
  declarations: [ClosePaymentOptionsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRadioModule,
    OverlayModule,
    TranslateModule,
    HeaderModule,
    TestPaymentBannerModule,
  ],
  providers: [
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  ],
  exports: [ClosePaymentOptionsComponent],
})
export class ClosePaymentOptionsModule {}
