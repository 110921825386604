import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { DatadogInterceptor } from '../interceptors';

export const DatadogInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: DatadogInterceptor,
  multi: true,
};
