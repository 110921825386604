<main class="zenkipay-page-header-padding">
  <section class="header">
    <article
      class="header__logo-container"
      [class.w-100]="showBigLogo"
      *ngIf="isDiscountActive; else onlyLogo"
    >
      <ng-template [ngTemplateOutlet]="zenkipayLogo"></ng-template>
      <div *ngIf="showDiscount" class="header__discount-container">
        <ui-kit-tooltip>
          <span class="tooltip-trigger header__discount">
            <img
              alt=""
              class="heart"
              src="assets/global/img/heart-white.svg"
            />&nbsp;<span
              [innerHTML]="
                'header.discount'
                  | translate
                    : {
                        discount:
                          this.discount!.discountType === 'PERCENT'
                            ? this.discount?.valueDiscount + '%'
                            : (this.discount?.valueDiscount
                              | currency
                                : this.discount?.currency!
                                : CURRENCY_FORMAT)
                      }
              "
            >
            </span>
          </span>
          <span class="tooltip-content crypto-lover">
            <div
              class="title"
              [innerHTML]="'crypto_lover.title' | translate"
            ></div>
            <div
              class="description"
              [innerHTML]="
                'crypto_lover.description'
                  | translate
                    : {
                        discount:
                          this.discount!.discountType === 'PERCENT'
                            ? this.discount?.valueDiscount + '%'
                            : (this.discount?.valueDiscount
                              | currency
                                : this.discount?.currency!
                                : CURRENCY_FORMAT)
                      }
              "
            ></div>
          </span>
        </ui-kit-tooltip>
        <span
          class="discount-changed"
          *ngIf="isDiscountChanged"
          [innerHTML]="'crypto_lover.discount_changed' | translate"
        ></span>
      </div>
    </article>
    <ng-template #onlyLogo>
      <ng-template [ngTemplateOutlet]="zenkipayLogo"></ng-template>
    </ng-template>

    <article class="header__language" *ngIf="!amount">
      <pbw-shared-language-select></pbw-shared-language-select>
    </article>

    <article [class.price]="!discount" *ngIf="amount">
      <p class="price__copy">
        <span [innerHTML]="'header.price' | translate"></span>&nbsp;<span
          *ngIf="isDiscountActive"
          class="original"
        >
          <span
            [innerHTML]="amount | currency : currency : CURRENCY_FORMAT"
          ></span
          >&nbsp;<span [innerHTML]="currency"></span>
        </span>
      </p>
      <div class="price__quantity">
        <p class="quantity">
          <span
            [innerHTML]="finalAmount | currency : currency : CURRENCY_FORMAT"
          ></span
          ><sup class="currency">{{ currency }}</sup>
        </p>
      </div>
    </article>
  </section>
</main>

<ng-template #zenkipayLogo>
  <img
    class="header__logo"
    [class.header__only-logo]="showBigLogo"
    [src]="isTestPayment ? ZENKIPAY_LOGO_TEST : ZENKIPAY_LOGO"
    alt="Zenkipay"
  />
</ng-template>
