import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { HttpErrorsHandlerInterceptor } from '../interceptors';

export const HttpErrorsHandlerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorsHandlerInterceptor,
  multi: true,
};
