import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { RequestMetricsInterceptor } from '../../interceptors/request-metrics';

export const RequestMetricsProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: RequestMetricsInterceptor,
  multi: true,
};
