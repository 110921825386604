<main id="friendly-error-handler" class="zenkipay-page">
  <section class="zenkipay-page-header">
    <ng-content></ng-content>
  </section>
  <section id="friendly-error-handler-body" class="zenkipay-page-body">
    <article id="friendly-error-handler-body-description">
      <h1
        class="title"
        [innerHTML]="'friendly_handler.' + viewName + '.title' | translate"
      ></h1>
      <div class="description">
        <span
          [innerHTML]="
            'friendly_handler.' + viewName + '.description1' | translate
          "
        ></span>
      </div>
      <div
        class="description"
        *ngIf="hasInfo('friendly_handler.' + viewName + '.description2')"
      >
        <span
          [innerHTML]="
            'friendly_handler.' + viewName + '.description2' | translate
          "
        ></span>
      </div>
      <div
        class="description"
        *ngIf="hasInfo('friendly_handler.' + viewName + '.description3')"
      >
        <span
          [innerHTML]="
            'friendly_handler.' + viewName + '.description3' | translate
          "
        ></span>
      </div>
    </article>
    <article id="friendly-error-handler-body-zenkibot">
      <img
        id="friendly-error-handler-body-zenkibot-desktop"
        src="assets/global/img/zenkibot-friendly-error-desktop.svg"
        alt="Zenkibot"
      />
      <img
        id="friendly-error-handler-body-zenkibot-mobile"
        src="assets/global/img/zenkibot-friendly-error-mobile.svg"
        alt="Zenkibot"
      />
    </article>
  </section>
  <section id="friendly-error-handler-footer" class="zenkipay-page-footer">
    <div class="button__container">
      <button
        id="back"
        type="button"
        title="back"
        mat-flat-button
        color="primary"
        (click)="goBack()"
        [innerHTML]="
          'friendly_handler.' + viewName + '.first_btn_title' | translate
        "
      ></button>

      <button
        *ngIf="hasSecondBtn"
        id="close"
        type="button"
        title="back"
        mat-stroked-button
        color="primary"
        (click)="goClose()"
        [innerHTML]="
          'friendly_handler.' + viewName + '.second_btn_title' | translate
        "
      ></button>
    </div>
    <div class="redirect-message">
      <span
        *ngIf="seconds !== null; else empty"
        [innerHTML]="
          'friendly_handler.' +
            viewName +
            '.redirect_message.' +
            (seconds === 1 ? 'singular' : 'plural') | translate : { seconds }
        "
      ></span>
      <ng-template #empty>
        <span>&nbsp;</span>
      </ng-template>
    </div>
  </section>
  <section *ngIf="appVersion" class="zenkipay-app-version">
    v{{ appVersion }}
  </section>
</main>
