import { Subscription } from 'rxjs';

type FnOnDestroy = (() => Promise<void> | void) | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Property = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Constructor = any;

export function AutoUnsubscribe(constructor: Constructor): void {
  const onDestroy: FnOnDestroy = constructor.prototype?.ngOnDestroy;

  constructor.prototype.ngOnDestroy = function (): void {
    Object.keys(this).forEach((key: string): void => {
      const property: Property = this[key];
      if (!property) return;

      if (property instanceof Subscription) {
        return property.unsubscribe();
      }

      if (Array.isArray(property)) {
        property
          .filter((prop: Property): boolean => prop instanceof Subscription)
          .forEach((prop: Subscription): void => prop.unsubscribe());
      }
    });

    if (typeof onDestroy === 'function') {
      onDestroy.apply(this);
    }
  };
}
