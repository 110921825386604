import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterContentInit,
  ChangeDetectorRef,
  Input,
  HostListener,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { WINDOW } from '@shared/angular/constants';
import { delay } from '@shared/common/functions/time';

@Component({
  selector: 'ui-kit-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements AfterContentInit {
  @ViewChild('trigger') private readonly _triggerRef!: ElementRef;
  private get _trigger(): HTMLElement {
    return this._triggerRef?.nativeElement;
  }

  @ViewChild('content') private readonly _contentRef!: ElementRef;

  @Input() public position: 'bottom' | 'top' = 'bottom';

  @Input() public width = 160;
  public get widthPx(): string {
    return `${this.width}px`;
  }
  private _left!: number;
  public get leftPx(): string {
    return `-${this._left}px`;
  }

  @Output() public readonly isShowing: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  private readonly _DEFAULT_DECORATOR_LEFT = 'calc(50% - 0.5rem)';
  public decoratorLeftPx = this._DEFAULT_DECORATOR_LEFT;

  public show = false;
  public hideAnimation = false;
  private readonly _hideDelay = 500;

  constructor(
    @Inject(WINDOW) private readonly _window: Window,
    private readonly _cdr: ChangeDetectorRef
  ) {}

  //#region LIFECYCLE

  public ngAfterContentInit(): void {
    this._cdr.detectChanges();
  }

  //#endregion LIFECYCLE

  //#region PUBLIC

  public toggleTooltip(): void {
    if (this.show) this._hideTooltip();
    else this._showTooltip();
  }

  //#endregion PUBLIC

  //#region PRIVATE

  @HostListener('document:click', ['$event'])
  private _closeWalletInfoListener(event: Event): void {
    if (this._triggerRef?.nativeElement?.contains(event.target)) return;
    if (this._contentRef?.nativeElement?.contains(event.target)) return;
    if (this.show) this._hideTooltip();
  }

  private _showTooltip(): void {
    this._updateLeft();
    this.show = true;
    this.isShowing.emit(this.show);
    this.hideAnimation = false;
    this._cdr.detectChanges();
  }

  private async _hideTooltip(): Promise<void> {
    this.hideAnimation = true;
    await delay(this._hideDelay);

    this.show = false;
    this.isShowing.emit(this.show);
    this._cdr.detectChanges();
  }

  private _updateLeft(): void {
    this._left = this.width / 2 + this._trigger?.offsetWidth / 2;
    const { left }: DOMRect = this._trigger.getBoundingClientRect();
    const totalWidth = left + this.width / 1.5;

    if (totalWidth < this._window.innerWidth) {
      this.decoratorLeftPx = this._DEFAULT_DECORATOR_LEFT;
      this._cdr.detectChanges();
      return;
    }

    const difference = (totalWidth - this._window.innerWidth) / 1.5;
    this.decoratorLeftPx = `calc(50% - 0.5rem + ${difference}px)`;
    this._left += difference;
    this._cdr.detectChanges();
  }

  //#endregion PRIVATE
}
