import { Injectable, NgZone } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Authorization } from '@shared/entities';
import { Observable, of } from 'rxjs';

import { ZENKIPAY_KEY } from '../../constants';
import { BaseApiService } from '../base-api';
import { SessionStorageService } from '../storage';
import { AuthenticationService } from './authentication.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Credentials = any;

@Injectable({ providedIn: 'any' })
export class EmptyAuthenticationService extends AuthenticationService<Credentials> {
  public override get credentials(): Credentials {
    return this._credentials$.value;
  }
  public override set credentials(credentials: Credentials) {
    this._storageService.set<Credentials>(ZENKIPAY_KEY, credentials);
    this._credentials$.next(credentials);
  }

  constructor(
    zone: NgZone,
    jwtService: JwtHelperService,
    storageService: SessionStorageService,
    apiService: BaseApiService
  ) {
    super(zone, jwtService, storageService, apiService);
  }

  public override getAuthorization(): Observable<Authorization> {
    return of({
      access_token: '',
      expires_in: 0,
      refresh_token: '',
      refresh_expires_in: 0,
      token_type: '',
    });
  }

  protected override _refreshAuthorization(): Observable<Authorization> {
    return of({
      access_token: '',
      expires_in: 0,
      refresh_token: '',
      refresh_expires_in: 0,
      token_type: '',
    });
  }
}
