import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL } from '@environment/variables';
import { Observable } from 'rxjs/internal/Observable';

import { BaseHttpBodyJsonOptions } from '../../models';

@Injectable({ providedIn: 'root' })
export class BaseApiService {
  constructor(private http: HttpClient) {}

  public get<T>(
    path: string,
    options?: BaseHttpBodyJsonOptions
  ): Observable<T> {
    return this.http.get<T>(`${API_URL}${path}`, options);
  }

  public put<T, D = unknown>(
    path: string,
    body: D,
    options?: BaseHttpBodyJsonOptions
  ): Observable<T> {
    return this.http.put<T>(`${API_URL}${path}`, body, options);
  }

  public patch<T, D = unknown>(
    path: string,
    body: D,
    options?: BaseHttpBodyJsonOptions
  ): Observable<T> {
    return this.http.patch<T>(`${API_URL}${path}`, body, options);
  }

  public post<T, D = unknown>(
    path: string,
    body: D,
    options?: BaseHttpBodyJsonOptions
  ): Observable<T> {
    return this.http.post<T>(`${API_URL}${path}`, body, options);
  }

  public delete<T>(
    path: string,
    options?: BaseHttpBodyJsonOptions
  ): Observable<T> {
    return this.http.delete<T>(`${API_URL}${path}`, options);
  }
}
