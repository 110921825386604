import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  Event as RouterEvent,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { SHOPPER_PAYMENT_PORTAL_VERSION as VERSION } from '@environment/variables';
import { CloseButtonHandlerAction } from '@core/pay/orchestrators/close-button-handler';
import { MerchantService } from '@core/pay/modules/merchant/services';
import { AutoUnsubscribe } from '@shared/angular/decorators/auto-unsubscribe';
import { LoadingService } from '@shared/angular/modules/loading/services';
import { WALLET_INTEGRATION } from '@shared/entities/modules/wallet/modules/integrations/enums/wallet-integration';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'pbw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe
export class AppComponent implements OnInit {
  public readonly subscriptions: Subscription[] = [];

  public readonly isTestPayment$: Observable<boolean>;
  public readonly appVersion: string = VERSION;

  private readonly _URL_PAYMENT = '/payment';
  private readonly _URL_BINANCE_PAY = `/wallets/${WALLET_INTEGRATION.BINANCE_PAY}`;
  private readonly _URL_PROCESSING = '/processing';

  constructor(
    private readonly _router: Router,
    private readonly _loadingService: LoadingService,
    private readonly _closeButtonHandlerAction: CloseButtonHandlerAction,
    merchantService: MerchantService
  ) {
    this.isTestPayment$ = merchantService.isTestPayment$;
  }

  //#region LIFECYCLE

  public ngOnInit(): void {
    this.subscriptions.push(
      this._handleSpinnerWhenRouting(),
      this._handleCloseModalButtonEvents()
    );
  }

  //#endregion LIFECYCLE

  //#region PRIVATE

  private _handleSpinnerWhenRouting(): Subscription {
    let currentRoute: string | null = null;

    return this._router.events.subscribe((event: RouterEvent): void => {
      if (event instanceof NavigationEnd) {
        currentRoute = event.url;
        this._loadingService.hide(currentRoute);
        currentRoute = null;
        return;
      }

      if (event instanceof NavigationStart) {
        if (!currentRoute || currentRoute === event.url) {
          currentRoute = event.url;
          return this._loadingService.show(currentRoute);
        }

        if (currentRoute && currentRoute !== event.url) {
          this._loadingService.hide(currentRoute);
          currentRoute = event.url;
          this._loadingService.show(currentRoute);
        }
      }
    });
  }

  private _handleCloseModalButtonEvents(): Subscription {
    return this._closeButtonHandlerAction
      .listenCloseModalButtonEvents()
      .subscribe((): void => {
        const url: string = this._router.url;

        if (url.startsWith(this._URL_PAYMENT)) {
          return this._closeButtonHandlerAction.confirmCancelWhenIsPaying();
        }

        if (url.startsWith(this._URL_BINANCE_PAY)) {
          return this._closeButtonHandlerAction.confirmCancelWhenIsPaying();
        }

        if (url.startsWith(this._URL_PROCESSING)) {
          return this._closeButtonHandlerAction.confirmClose();
        }

        return this._closeButtonHandlerAction.confirmCancel();
      });
  }

  //#endregion PRIVATE
}
