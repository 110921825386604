import { options } from '@shared/common/constants';
import { Order } from '@shared/entities/modules/order/models/order';
import { ObjectSchema } from 'joi';
import * as Joi from 'joi';

import { purchaseSummarySchema } from './purchase-summary';

const orderSchema: ObjectSchema<Order> = Joi.object<Order>().keys({
  orderId: Joi.string().required(),
  merchantUnitId: Joi.string().required(),
  shopperEmail: Joi.string().allow(null).allow('').optional(),
  purchaseSummary: purchaseSummarySchema,
});

export async function validateOrder(order: Order): Promise<Order> {
  return orderSchema.validateAsync(order, options);
}
