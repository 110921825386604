import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  catchError,
  EMPTY,
  Observable,
  switchMap,
  take,
  throwError,
} from 'rxjs';

import {
  SkipAllHttpErrorInterceptor,
  SkipHttpErrorInterceptor,
} from '../../../constants';
import { verifyHttpCodeInHeaders } from '../../../functions/verify-http-code-in-headers';
import { HttpErrorsHandlerService } from '../services';
import {
  BaseFriendlyErrorComponent,
  BaseFriendlyErrorService,
} from '../../friendly-error-view/classes';
import { ZenkipayEventsAction } from '../../../orchestrators/zenkipay-events';

@Injectable()
export class HttpErrorsHandlerInterceptor implements HttpInterceptor {
  private readonly _URL_EMAIL = '/email';
  private readonly _URL_MAIN = '/';
  private readonly _URL_REFRESH_TOKEN =
    '/public/v2/merchants/plugin/refresh-token';

  constructor(
    private readonly _router: Router,
    private readonly _location: Location,
    private readonly _httpErrorsHandlerService: HttpErrorsHandlerService,
    private readonly _friendlyErrorHandlerService: BaseFriendlyErrorService<BaseFriendlyErrorComponent>,
    private readonly _zenkipayEventsAction: ZenkipayEventsAction
  ) {}

  //#region PUBLIC

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
        let applyInterceptor = !request.headers.has(
          SkipAllHttpErrorInterceptor
        );
        if (!applyInterceptor) {
          applyInterceptor = !verifyHttpCodeInHeaders(
            SkipHttpErrorInterceptor,
            request.headers,
            error.status
          );
        }

        if (
          request.url.includes(this._URL_REFRESH_TOKEN) &&
          error.status === 401
        ) {
          this._router.navigate(['order-expired']);
          return EMPTY;
        }

        if (applyInterceptor) return this._go2ErrorPage(error);
        return throwError((): HttpErrorResponse => error) as Observable<
          HttpEvent<unknown>
        >;
      })
    );
  }

  //#endregion PUBLIC

  //#region PRIVATE

  private _go2ErrorPage(
    error: HttpErrorResponse
  ): Observable<HttpEvent<unknown>> {
    this._httpErrorsHandlerService.setError(error);
    return this._friendlyErrorHandlerService.openFriendlyErrorHandler().pipe(
      take(1),
      switchMap((): Observable<never> => {
        if (this._router.url === this._URL_MAIN) {
          this._zenkipayEventsAction.cancel();
          return EMPTY;
        }
        if (this._router.url !== this._URL_EMAIL) {
          this._location.back();
          return EMPTY;
        }
        return EMPTY;
      })
    );
  }

  //#endregion PRIVATE
}
