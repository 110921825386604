import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';

@Injectable()
export class RequestMetricsInterceptor implements HttpInterceptor {
  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const url = `[${request.method.toUpperCase()}] ${request.url}`;
    const start: number = performance.now();

    return next.handle(request).pipe(
      tap(({ type }: HttpEvent<unknown>): void => {
        if (type !== HttpEventType.Response) return;
        const duration: number = performance.now() - start;
        console.log('%s in %dms', url, duration);
      }),
      catchError((error: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
        const duration: number = performance.now() - start;
        console.error('%s in %dms', url, duration);
        return throwError((): HttpErrorResponse => error) as Observable<
          HttpEvent<unknown>
        >;
      })
    );
  }
}
