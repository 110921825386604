import { POST_MSG_TYPE } from '../enums';
import { ConfirmingMsg, DoneMsg } from '../models';

export class PostMessage<Data = unknown, Type = POST_MSG_TYPE> {
  constructor(public readonly type: Type, public readonly data: Data) {}
}

export class PostShopperPaymentConfirmationMessage extends PostMessage<
  null,
  POST_MSG_TYPE
> {
  constructor() {
    super(POST_MSG_TYPE.SHOPPER_PAYMENT_CONFIRMATION, null);
  }
}

export class PostCancelMessage extends PostMessage<null, POST_MSG_TYPE> {
  constructor() {
    super(POST_MSG_TYPE.CANCEL, null);
  }
}

export class PostCloseMessage extends PostMessage<
  DoneMsg | null,
  POST_MSG_TYPE
> {
  constructor(data: DoneMsg | null = null) {
    super(POST_MSG_TYPE.CLOSE, data);
  }
}

export class PostDoneMessage extends PostMessage<DoneMsg, POST_MSG_TYPE> {
  constructor(data: DoneMsg) {
    super(POST_MSG_TYPE.DONE, data);
  }
}

export class PostErrorMessage<Data = unknown> extends PostMessage<
  Data,
  POST_MSG_TYPE
> {
  constructor(error: Data) {
    super(POST_MSG_TYPE.ERROR, error);
  }
}

export class PostHideCloseBtnMessage extends PostMessage<null, POST_MSG_TYPE> {
  constructor() {
    super(POST_MSG_TYPE.HIDE_CLOSE_BTN, null);
  }
}

export class PostEnableCloseBtnMessage extends PostMessage<
  null,
  POST_MSG_TYPE
> {
  constructor() {
    super(POST_MSG_TYPE.ENABLE_CLOSE_BTN, null);
  }
}

export class PostDisableCloseBtnMessage extends PostMessage<
  null,
  POST_MSG_TYPE
> {
  constructor() {
    super(POST_MSG_TYPE.DISABLE_CLOSE_BTN, null);
  }
}

export class PostProcessingPaymentMessage extends PostMessage<
  ConfirmingMsg | null,
  POST_MSG_TYPE
> {
  constructor(confirmingMsg?: ConfirmingMsg) {
    super(POST_MSG_TYPE.PROCESSING_PAYMENT, confirmingMsg || null);
  }
}
