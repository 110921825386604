import {
  Component,
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { AutoUnsubscribe } from '@shared/angular/decorators/auto-unsubscribe';
import { Order } from '@shared/entities/modules/order/models/order';
import { Subscription, filter, Observable } from 'rxjs';

import { MerchantService } from '../../../modules/merchant/services';
import { OrderService } from '../../../modules/order/services';
import { Discount } from '@shared/entities/modules/coupon';

@Component({
  selector: 'pbw-pay-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe
export class HeaderComponent implements AfterContentInit {
  public readonly subscriptions: Subscription[] = [];

  @Input() public showBigLogo = false;
  @Input() public showAmount = true;
  @Input() public showDiscount = true;

  public amount!: number;
  public currency!: string;
  public discount!: Discount | null;
  public finalAmount!: number;
  public isDiscountActive!: boolean;
  public isDiscountChanged!: boolean;

  public readonly isTestPayment$: Observable<boolean>;

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _merchantService: MerchantService,
    private readonly _orderService: OrderService
  ) {
    this.isTestPayment$ = _merchantService.isTestPayment$;
  }

  //#region LIFECYCLE

  public ngAfterContentInit(): void {
    this.subscriptions.push(
      this._handleMerchantDetails(),
      this._handleCryptoLovePercentageChanges()
    );
    if (this.showAmount) {
      this.subscriptions.push(
        this._handleOrderDetails(),
        this._handleFinalAmountDetails()
      );
    }
  }

  //#endregion LIFECYCLE

  //#region PRIVATE

  private _handleMerchantDetails(): Subscription {
    return this._merchantService.discount$.subscribe(
      (discount: Discount | null): void => {
        this.isDiscountActive = !!discount;
        this.discount = discount;
        this._cdr.detectChanges();
      }
    );
  }

  private _handleCryptoLovePercentageChanges(): Subscription {
    return this._merchantService.isCryptoLovePercentageChanged$.subscribe(
      (isDiscountChanged: boolean): void => {
        this.isDiscountChanged = isDiscountChanged;
        this._cdr.detectChanges();
      }
    );
  }

  private _handleOrderDetails(): Subscription {
    return this._orderService.order$
      .pipe(filter(Boolean))
      .subscribe(
        ({ purchaseSummary: { currency, grandTotalAmount } }: Order): void => {
          this.amount = +grandTotalAmount;
          this.currency = currency;
          this._cdr.detectChanges();
        }
      );
  }

  private _handleFinalAmountDetails(): Subscription {
    return this._orderService.finalAmount$.subscribe(
      (finalAmount: number | string): void => {
        this.finalAmount = +finalAmount;
        this._cdr.detectChanges();
      }
    );
  }

  //#endregion PRIVATE
}
