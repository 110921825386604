<span class="trigger-container" #trigger (click)="toggleTooltip()">
  <ng-content select=".tooltip-trigger"></ng-content>
</span>
<span class="tooltip-container">
  <span
    #content
    class="tooltip"
    [class.hide]="!show"
    [class.show-animated]="show"
    [class.hide-animated]="hideAnimation"
    [class.bottom]="position === 'bottom'"
    [class.top]="position === 'top'"
    [style.width]="widthPx"
    [style.left]="leftPx"
  >
    <span
      class="decorator decorator-bottom"
      [style.left]="decoratorLeftPx"
      *ngIf="position === 'bottom'"
    ></span>
    <ng-content select=".tooltip-content"></ng-content>
    <span
      class="decorator decorator-top"
      [style.left]="decoratorLeftPx"
      *ngIf="position === 'top'"
    ></span>
  </span>
</span>
