import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Authorization } from '@shared/entities/common/models/authorization';
import { Observable, switchMap } from 'rxjs';

import { SkipAuthInterceptor } from '../../constants';
import { AuthenticationService } from '../../services/authentication';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly _authService: AuthenticationService) {}

  //#region PUBLIC

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.has(SkipAuthInterceptor)) {
      return next.handle(request);
    }

    return this._authService.getAuthorization().pipe(
      switchMap(
        (authorization: Authorization): Observable<HttpEvent<unknown>> => {
          return next.handle(this._addHeaders(request, authorization));
        }
      )
    );
  }

  //#endregion PUBLIC

  //#region PRIVATE

  private _addHeaders(
    request: HttpRequest<unknown>,
    { token_type, access_token }: Authorization
  ): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `${token_type} ${access_token}`,
      },
    });
  }

  //#endregion PRIVATE
}
