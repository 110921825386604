import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'pbw-shared-friendly-error-view',
  templateUrl: './friendly-error-view.component.html',
  styleUrls: ['./friendly-error-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatButtonModule, TranslateModule],
})
export class FriendlyErrorViewComponent {
  @Input() public seconds: number | null = null;
  @Input() public appVersion: string | null = null;
  @Input() public viewName: string | null = null;
  @Input() public hasSecondBtn: boolean | null = false;
  @Output() public readonly back: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly closeModal: EventEmitter<void> =
    new EventEmitter<void>();

  constructor(private readonly _translate: TranslateService) {}

  public goBack(): void {
    this.back.emit();
  }

  public goClose(): void {
    this.closeModal.emit();
  }

  public hasInfo(name: string): boolean {
    const msg = this._translate.instant(name);

    return msg !== name;
  }
}
