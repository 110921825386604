import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { SUPPORTED_LANGUAGES } from '../../constants';
import { Language } from '../../models';
import { I18nService } from '../../services';

@Component({
  selector: 'pbw-shared-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe
export class LanguageSelectComponent implements OnInit {
  @ViewChild('languageMenu')
  private readonly _languageMenu!: ElementRef<HTMLElement>;
  @ViewChild('languageSelect')
  private readonly _selectLanguage!: ElementRef<HTMLElement>;

  public subscription!: Subscription;
  public selectedLanguage!: Language;

  public get languages(): Language[] {
    return SUPPORTED_LANGUAGES;
  }

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _i18nService: I18nService
  ) {
    this.selectedLanguage = this._i18nService.current;
  }

  public ngOnInit(): void {
    this.subscription = this._dismissMenuListener();
  }

  public onChangeLanguage(language: Language): void {
    this._i18nService.current = language;
    this.selectedLanguage = language;
    this.toggleMenu();
    this._cdr.detectChanges();
  }

  public toggleMenu(): void {
    this._languageMenu.nativeElement.classList.toggle('show');
  }

  private _dismissMenuListener(): Subscription {
    return fromEvent(this._document, 'click').subscribe((event): void => {
      if (
        !this._languageMenu.nativeElement.contains(event.target as Node) &&
        this._languageMenu.nativeElement.classList.contains('show') &&
        !this._selectLanguage.nativeElement.contains(event.target as Node)
      ) {
        this._languageMenu.nativeElement.classList.remove('show');
      }
    });
  }
}
