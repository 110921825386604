import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { TestPaymentBannerComponent } from './test-payment-banner.component';

@NgModule({
  declarations: [TestPaymentBannerComponent],
  imports: [CommonModule, TranslateModule],
  exports: [TestPaymentBannerComponent],
})
export class TestPaymentBannerModule {}
