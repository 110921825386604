import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  API_VERSION,
  SkipHttpErrorInterceptor,
} from '@shared/angular/constants';
import { VERSION } from '@shared/angular/enums/version';
import { BaseApiService } from '@shared/angular/services/base-api';
import { HttpResponseValidatorService } from '@shared/angular/services/http-response-validator';
import { SessionStorageService } from '@shared/angular/services/storage';
import { HTTP_CODE_ALREADY_PAID } from '@shared/common/constants';
import { Order } from '@shared/entities/modules/order/models/order';
import { OrderDetails } from '@shared/entities/modules/order/models/order-details';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';

import { FINAL_AMOUNT, ORDER } from '../constants';
import { validateOrder } from '../validators';

@Injectable({ providedIn: 'root' })
export class OrderService {
  private readonly _order$: BehaviorSubject<Order | null> =
    new BehaviorSubject<Order | null>(this._storageService.get<Order>(ORDER));
  public readonly order$: Observable<Order | null> =
    this._order$.asObservable();

  private readonly _finalAmount$: BehaviorSubject<number | string> =
    new BehaviorSubject<number | string>(0);
  public readonly finalAmount$: Observable<number | string> =
    this._finalAmount$.asObservable();

  public get finalAmount(): number | string {
    return this._finalAmount$.value;
  }
  public set finalAmount(finalAmount: number | string) {
    this._storageService.set<number | string>(FINAL_AMOUNT, finalAmount);
    this._finalAmount$.next(finalAmount);
  }

  public set order(order: Order) {
    this._storageService.set<Order>(ORDER, order);
    this._order$.next(order);
  }

  constructor(
    @Inject(API_VERSION) private readonly _apiVersion: VERSION,
    private readonly _apiService: BaseApiService,
    private readonly _storageService: SessionStorageService,
    private readonly _httpResponseValidatorService: HttpResponseValidatorService
  ) {}

  public getOrder(orderId: string): Observable<Order> {
    const url = `${this._apiVersion}/orders`;
    const headers: HttpHeaders = new HttpHeaders({
      [SkipHttpErrorInterceptor]: `${HTTP_CODE_ALREADY_PAID}`,
    });
    const params: HttpParams = new HttpParams({ fromObject: { orderId } });
    return this._apiService.get<Order>(url, { headers, params }).pipe(
      tap((order: Order): void => {
        this._storageService.set<Order>(ORDER, order);
        this._order$.next(order);

        if (order.fiatAmountWithDiscount) {
          this.finalAmount = order.fiatAmountWithDiscount;
        }
      }),
      switchMap(
        this._httpResponseValidatorService.validate(validateOrder, {
          method: 'GET',
          params,
          url,
        })
      )
    );
  }

  public patchOrder(
    orderId: string,
    body: Partial<OrderDetails>
  ): Observable<Order> {
    const url = `${this._apiVersion}/orders/${orderId}`;
    return this._apiService.patch<Order>(url, body).pipe(
      tap((order: Order): void => {
        this._storageService.set<Order>(ORDER, order);
        this._order$.next(order);
      }),
      switchMap(
        this._httpResponseValidatorService.validate(validateOrder, {
          method: 'PATCH',
          body,
          url,
        })
      )
    );
  }
}
