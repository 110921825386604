import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PATH_HTTP_ERROR, PATH_WARN_MESSAGE } from '@shared/common/constants';
import { BehaviorSubject, Observable } from 'rxjs';

import { HTTP_ERROR } from '../../../constants';
import { SessionStorageService } from '../../../services/storage';

@Injectable({ providedIn: 'root' })
export class HttpErrorsHandlerService {
  private readonly _error$: BehaviorSubject<HttpErrorResponse | null> =
    new BehaviorSubject<HttpErrorResponse | null>(
      this._storageService.get<HttpErrorResponse>(HTTP_ERROR)
    );
  public readonly error$: Observable<HttpErrorResponse | null> =
    this._error$.asObservable();

  private readonly _pathUrlError$: BehaviorSubject<string> =
    new BehaviorSubject<string>(PATH_HTTP_ERROR);
  public readonly pathUrlError$: Observable<string> =
    this._pathUrlError$.asObservable();

  constructor(private readonly _storageService: SessionStorageService) {}

  public setError(error: HttpErrorResponse): void {
    this._storageService.set<HttpErrorResponse>(HTTP_ERROR, error);
    this._error$.next(error);
  }

  public setErrorHandlerType(type: 'error' | 'warn'): void {
    const pathUrl: string = type === 'error' ? HTTP_ERROR : PATH_WARN_MESSAGE;
    this._pathUrlError$.next(pathUrl);
  }
}
