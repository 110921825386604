import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@shared/angular/constants';
import { ZenkipayEventsAction } from '@shared/angular/orchestrators/zenkipay-events';
import { BaseFriendlyErrorService } from '@shared/angular/modules/friendly-error-view/classes';
import { POST_MSG_TYPE } from '@shared/entities/common/enums';
import { filter, fromEvent, map, Observable, of, switchMap, take } from 'rxjs';

import { ClosePaymentOptionsService } from '../../core/close-payment-options';
import { CLOSE_PAYMENT_OPTIONS } from '../../core/close-payment-options/enums';
import { FriendlyErrorHandlerComponent } from '../../core/friendly-error-handler/components';

@Injectable({ providedIn: 'root' })
export class CloseButtonHandlerAction {
  private readonly _parentUrl: string;

  constructor(
    @Inject(WINDOW) private readonly _window: Window,
    private readonly _zenkipayEventsAction: ZenkipayEventsAction,
    private readonly _closePaymentOptionsService: ClosePaymentOptionsService,
    private readonly _friendlyErrorHandlerService: BaseFriendlyErrorService<FriendlyErrorHandlerComponent>
  ) {
    this._parentUrl = _window.name || '*';
  }

  //#region PUBLIC

  public listenCloseModalButtonEvents(): Observable<symbol> {
    return fromEvent<MessageEvent<POST_MSG_TYPE>>(this._window, 'message').pipe(
      filter(({ data, origin }: MessageEvent<POST_MSG_TYPE>): boolean => {
        if (this._parentUrl !== '*' && this._parentUrl !== origin) return false;
        return data === POST_MSG_TYPE.CLOSE;
      }),
      map((): symbol => Symbol(POST_MSG_TYPE.CLOSE))
    );
  }

  public confirmCancel(): void {
    this._confirmCloseModal().subscribe((isConfirmed: boolean): void => {
      isConfirmed && this._zenkipayEventsAction.cancel();
    });
  }

  public confirmCancelWhenIsPaying(): void {
    this._zenkipayEventsAction.disableCloseBtn();
    this._closePaymentOptionsService
      .showCloseOptions()
      .subscribe((selection: CLOSE_PAYMENT_OPTIONS): void => {
        this._zenkipayEventsAction.enableCloseBtn();
        switch (selection) {
          case CLOSE_PAYMENT_OPTIONS.ALREADY_PAID_AND_CLOSE: {
            return this._zenkipayEventsAction.shopperPaymentConfirmation();
          }
          case CLOSE_PAYMENT_OPTIONS.CANCEL_AND_CLOSE: {
            return this._zenkipayEventsAction.cancel();
          }
        }
      });
  }

  public confirmClose(): void {
    this._confirmCloseModal().subscribe((isConfirmed: boolean): void => {
      isConfirmed && this._zenkipayEventsAction.close(null);
    });
  }

  //#endregion PUBLIC

  //#region PRIVATE

  private _confirmCloseModal(): Observable<boolean> {
    return this._friendlyErrorHandlerService.openFriendlyCloseHandler().pipe(
      take(1),
      switchMap(([, closeAll]: [boolean, boolean]): Observable<boolean> => {
        console.log(closeAll);
        if (closeAll) {
          this._zenkipayEventsAction.cancel();
        }
        return of(closeAll);
      })
    );
  }

  //#endregion PRIVATE
}
