import { NgZone, Provider } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { WINDOW } from '../../constants';
import { BaseApiService } from '../../services/base-api';
import { SessionStorageService } from '../../services/storage';
import {
  AuthenticationService,
  AuthenticationV1Service,
  AuthenticationV2Service,
  EmptyAuthenticationService,
} from '../../services/authentication';

function initializeAuthStrategies(
  zone: NgZone,
  jwtService: JwtHelperService,
  storageService: SessionStorageService,
  apiService: BaseApiService,
  window: Window
): AuthenticationService {
  const [, queryParams]: string[] = window.location.href.split('?');
  const params: URLSearchParams = new URLSearchParams(queryParams);

  const orderId: string | null = params.get('orderId');
  const paymentSignature: string | null = params.get('paymentSignature');
  const zenkipayKey: string | null = params.get('zenkipayKey');

  /** @version v2 */
  if (orderId && paymentSignature) {
    return new AuthenticationV2Service(
      zone,
      jwtService,
      storageService,
      apiService
    );
  }

  /** @version v1 */
  if (orderId && zenkipayKey) {
    return new AuthenticationV1Service(
      zone,
      jwtService,
      storageService,
      apiService
    );
  }

  return new EmptyAuthenticationService(
    zone,
    jwtService,
    storageService,
    apiService
  );
}

export const AuthStrategyProvider: Provider = {
  provide: AuthenticationService,
  useFactory: initializeAuthStrategies,
  deps: [
    NgZone,
    JwtHelperService,
    SessionStorageService,
    BaseApiService,
    WINDOW,
  ],
};
