import { ComponentRef, Injectable } from '@angular/core';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Observable, Subject, forkJoin, take, tap } from 'rxjs';

import { BaseFriendlyErrorComponent } from './base.friendly-error.component';

@Injectable()
export abstract class BaseFriendlyErrorService<
  Component extends BaseFriendlyErrorComponent
> {
  private readonly _overlayRef!: OverlayRef;

  private _component?: ComponentRef<Component>;

  private readonly _attached$: Subject<boolean> = new Subject<boolean>();
  public readonly attached$: Observable<boolean> =
    this._attached$.asObservable();

  constructor(overlay: Overlay) {
    this._overlayRef = overlay.create();
  }

  //#region PUBLIC

  public openFriendlyErrorHandler(): Observable<boolean> {
    if (!this._component || !this._overlayRef.hasAttached()) {
      const componentPortal: ComponentPortal<Component> =
        this._createComponentPortal();

      this._component = this._overlayRef.attach(componentPortal);
      this._attached$.next(true);
    }
    return this._component.instance.close$.pipe(tap((): void => this.detach()));
  }

  public openFriendlyCloseHandler(): Observable<[boolean, boolean]> {
    if (!this._component || !this._overlayRef.hasAttached()) {
      const componentPortal: ComponentPortal<Component> =
        this._createComponentPortalClose();

      this._component = this._overlayRef.attach(componentPortal);
    }

    return forkJoin([
      this._component.instance.close$.pipe(take(1)),
      this._component.instance.closeAll$.pipe(take(1)),
    ]).pipe(tap((): void => this.detach()));
  }

  public openFriendlyActionHandler(
    viewName: string
  ): Observable<[boolean, boolean]> {
    if (!this._component || !this._overlayRef.hasAttached()) {
      const componentPortal: ComponentPortal<Component> =
        this._createComponentPortalAction(viewName);

      this._component = this._overlayRef.attach(componentPortal);
    }

    return forkJoin([
      this._component.instance.close$.pipe(take(1)),
      this._component.instance.closeAll$.pipe(take(1)),
    ]).pipe(tap((): void => this.detach()));
  }

  public detach(): void {
    this._component && this._overlayRef.detach();
    this._component = undefined;
  }

  //#endregion PUBLIC

  //#region PROTECTED

  protected abstract _createComponentPortal(): ComponentPortal<Component>;

  protected abstract _createComponentPortalClose(): ComponentPortal<Component>;

  protected abstract _createComponentPortalAction(
    viewName: string
  ): ComponentPortal<Component>;

  //#endregion PROTECTED
}
