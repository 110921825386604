export enum POST_MSG_TYPE {
  CANCEL = 'cancel',
  DONE = 'done',
  CLOSE = 'close',
  ERROR = 'error',
  HIDE_CLOSE_BTN = 'hide_close_btn',
  ENABLE_CLOSE_BTN = 'enable_close_btn',
  DISABLE_CLOSE_BTN = 'disable_close_btn',
  PROCESSING_PAYMENT = 'processing_payment',
  SHOPPER_PAYMENT_CONFIRMATION = 'shopper_payment_confirmation',
}
