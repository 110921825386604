import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { ZenkipayOptions } from '@shared/entities/script/v2/models';
import { Observable, switchMap } from 'rxjs';

import { PaymentSignatureHeader } from '../../constants';
import { AuthenticationService } from '../../services/authentication';

@Injectable()
export class HttpPaymentSignatureInterceptor implements HttpInterceptor {
  constructor(
    private readonly _authService: AuthenticationService<ZenkipayOptions>
  ) {}

  //#region PUBLIC

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this._authService.credentials$.pipe(
      switchMap(
        (
          credentials: ZenkipayOptions | null
        ): Observable<HttpEvent<unknown>> => {
          if (!credentials?.paymentSignature) return next.handle(request);
          return next.handle(this._addHeaders(request, credentials));
        }
      )
    );
  }

  //#endregion PUBLIC

  //#region PRIVATE

  private _addHeaders(
    request: HttpRequest<unknown>,
    { paymentSignature }: ZenkipayOptions
  ): HttpRequest<unknown> {
    return request.clone({
      setHeaders: { [PaymentSignatureHeader]: paymentSignature },
    });
  }

  //#endregion PRIVATE
}
