<pbw-shared-friendly-error-view
  [seconds]="seconds"
  [appVersion]="appVersion"
  [viewName]="viewName"
  [hasSecondBtn]="hasSecondBtn"
  (back)="back()"
  (closeModal)="close()"
>
  <pbw-pay-test-payment-banner></pbw-pay-test-payment-banner>
  <pbw-pay-header></pbw-pay-header>
</pbw-shared-friendly-error-view>
