import {
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ValidationError } from 'joi';
import {
  catchError,
  Observable,
  of,
  Subscriber,
  Subscription,
  take,
  TeardownLogic,
} from 'rxjs';

import { API_VERSION } from '../../constants';
import { VERSION } from '../../enums/version';
import {
  HttpResponseValidatorError,
  HttpResponseValidatorErrorParams,
  RequestDetails,
} from '../../models';
import { HttpErrorsHandlerService } from '../../modules/http-errors-handler/services';
import {
  BaseFriendlyErrorComponent,
  BaseFriendlyErrorService,
} from '../../modules/friendly-error-view/classes';
import { Location } from '@angular/common';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe';

@Injectable({ providedIn: 'root' })
@AutoUnsubscribe
export class HttpResponseValidatorService {
  public subscription!: Subscription;
  constructor(
    private readonly _location: Location,
    @Inject(API_VERSION) private readonly _apiVersion: VERSION,
    private readonly _httpErrorsHandlerService: HttpErrorsHandlerService,
    private readonly _friendlyErrorHandlerService: BaseFriendlyErrorService<BaseFriendlyErrorComponent>
  ) {}

  //#region PUBLIC

  public validate<Response = unknown, Body = unknown>(
    validatorFn: (response: Response, version: VERSION) => Promise<Response>,
    { method, url, body, headers, params }: RequestDetails<Body>
  ): (response: Response) => Observable<Response> {
    return (response: Response): Observable<Response> => {
      return new Observable<Response>(
        (subscriber: Subscriber<Response>): TeardownLogic => {
          validatorFn(response, this._apiVersion)
            .then((value: Response): void => {
              subscriber.next(value);
              subscriber.complete();
            })
            .catch((error: ValidationError): void => {
              const errorParams: HttpResponseValidatorErrorParams = {
                method,
                error,
                url,
                ...(body && { body }),
                ...(response && { response }),
                ...(params && {
                  params: this._parseHeadersOrParams(params),
                }),
                ...(headers && {
                  headers: this._parseHeadersOrParams(headers),
                }),
              };
              const validatorError: HttpResponseValidatorError =
                new HttpResponseValidatorError(errorParams);
              subscriber.error(validatorError);
              subscriber.complete();
            });
        }
      ).pipe(
        catchError(
          (error: HttpResponseValidatorError): Observable<Response> => {
            const httpError: HttpErrorResponse = new HttpErrorResponse({
              error: { ...error, humanMessage: error.message },
              statusText: '400',
              status: 400,
            });
            this._httpErrorsHandlerService.setError(httpError);
            this.subscription?.unsubscribe();
            this.subscription = this._friendlyErrorHandlerService
              .openFriendlyErrorHandler()
              .pipe(take(1))
              .subscribe((): void => this._location.back());
            return of(response);
          }
        )
      );
    };
  }

  //#endregion PUBLIC

  //#region PRIVATE

  private _parseHeadersOrParams(
    headersOrParams: HttpHeaders | HttpParams
  ): Record<string, string> {
    return headersOrParams
      .keys()
      .reduce(
        (acc: Record<string, string>, key: string): Record<string, string> => {
          const value: string | null = headersOrParams.get(key);
          if (value) acc[key] = value;
          return acc;
        },
        {}
      );
  }

  //#endregion PRIVATE
}
