import { Injectable, NgZone } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Authorization } from '@shared/entities/common/models/authorization';
import { ZenkipayOptions } from '@shared/entities/script/v2/models';

import {
  AUTHORIZATION,
  CREDENTIALS,
  PaymentSignatureHeader,
  SkipAuthInterceptor,
} from '../../constants';
import { BaseApiService } from '../base-api';
import { SessionStorageService } from '../storage';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'any' })
export class AuthenticationV2Service extends AuthenticationService<ZenkipayOptions> {
  public override get credentials(): ZenkipayOptions | null {
    return this._credentials$.value;
  }
  public override set credentials(credentials: ZenkipayOptions | null) {
    if (!credentials) return;
    this._storageService.set<ZenkipayOptions>(CREDENTIALS, credentials);
    this._credentials$.next(credentials);
    this._authenticate(credentials);
  }

  constructor(
    zone: NgZone,
    jwtService: JwtHelperService,
    storageService: SessionStorageService,
    apiService: BaseApiService
  ) {
    super(zone, jwtService, storageService, apiService);
    this._init();
  }

  private _authenticate({ orderId, paymentSignature }: ZenkipayOptions): void {
    const url = 'public/v2/merchants/plugin/token';
    const headers: HttpHeaders = new HttpHeaders({
      [PaymentSignatureHeader]: paymentSignature,
      [SkipAuthInterceptor]: '',
    });
    const params: HttpParams = new HttpParams({
      fromObject: { orderId },
    });
    this._unsubscribeAuthentication();
    this._authenticate$ = this._apiService
      .get<Authorization>(url, { headers, params })
      .subscribe((authorization: Authorization): void => {
        this._storageService.set<Authorization>(AUTHORIZATION, authorization);
        this._authorization$.next(authorization);
        this._unsubscribeAuthentication();
      });
  }
}
