/* eslint-disable @typescript-eslint/no-empty-function */
import { DatadogService } from './datadog.service';

export class DatadogLocalhostService extends DatadogService {
  public override startSessionReplayRecording(): void {}

  public override stopSessionReplayRecording(): void {}

  public override setUser(): void {}

  public override addError(): void {}

  public override addAction(): void {}
}
