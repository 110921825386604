export interface WALLET {
  name: string;
  legend: string;
  logo: string;
}

export const SUPPORTED_WALLETS: WALLET[] = [
  {
    name: 'WalletConnect',
    legend: 'Scan with WalletConnect',
    logo: 'assets/global/img/w-connect.png',
  },
  {
    name: 'MetaMask',
    legend: 'Connect to Metamask Wallet',
    logo: 'assets/global/img/metamask.png',
  },
  {
    name: 'WalletConnect',
    legend: 'Scan with WalletConnect',
    logo: 'assets/global/img/w-connect.png',
  },
  {
    name: 'WalletConnect',
    legend: 'Scan with WalletConnect',
    logo: 'assets/global/img/w-connect.png',
  },
  {
    name: 'WalletConnect',
    legend: 'Scan with WalletConnect',
    logo: 'assets/global/img/w-connect.png',
  },
  {
    name: 'WalletConnect',
    legend: 'Scan with WalletConnect',
    logo: 'assets/global/img/w-connect.png',
  },
  {
    name: 'WalletConnect',
    legend: 'Scan with WalletConnect',
    logo: 'assets/global/img/w-connect.png',
  },
  {
    name: 'WalletConnect',
    legend: 'Scan with WalletConnect',
    logo: 'assets/global/img/w-connect.png',
  },
];
