import { Injectable, Injector } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { BaseFriendlyErrorService } from '@shared/angular/modules/friendly-error-view/classes';

import { FriendlyErrorHandlerComponent } from '../components';
import { FRIENDLY_ERROR_PARAMS } from '../constants';

@Injectable({ providedIn: 'root' })
export class FriendlyErrorHandlerService extends BaseFriendlyErrorService<FriendlyErrorHandlerComponent> {
  protected override _createComponentPortal(): ComponentPortal<FriendlyErrorHandlerComponent> {
    return new ComponentPortal<FriendlyErrorHandlerComponent>(
      FriendlyErrorHandlerComponent,
      null,
      Injector.create({
        providers: [
          {
            provide: FRIENDLY_ERROR_PARAMS,
            useValue: { viewName: 'error', hasTimer: true },
          },
        ],
      })
    );
  }

  protected override _createComponentPortalClose(): ComponentPortal<FriendlyErrorHandlerComponent> {
    return new ComponentPortal<FriendlyErrorHandlerComponent>(
      FriendlyErrorHandlerComponent,
      null,
      Injector.create({
        providers: [
          {
            provide: FRIENDLY_ERROR_PARAMS,
            useValue: { viewName: 'dismiss', hasSecondBtn: true },
          },
        ],
      })
    );
  }

  protected override _createComponentPortalAction(
    viewName: string
  ): ComponentPortal<FriendlyErrorHandlerComponent> {
    return new ComponentPortal<FriendlyErrorHandlerComponent>(
      FriendlyErrorHandlerComponent,
      null,
      Injector.create({
        providers: [
          {
            provide: FRIENDLY_ERROR_PARAMS,
            useValue: { viewName: viewName, hasSecondBtn: true },
          },
        ],
      })
    );
  }
}
