import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PATH_HTTP_ERROR, PATH_WARN_MESSAGE } from '@shared/common/constants';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/start-page').then((m) => m.StartPageModule),
  },
  {
    path: PATH_WARN_MESSAGE,
    loadChildren: () =>
      import('./pages/warn-message').then((m) => m.WarnMessagePageModule),
  },
  {
    path: 'email',
    loadChildren: () =>
      import('./pages/email-form-page').then((m) => m.EmailFormPageModule),
  },
  {
    path: 'coupon',
    loadChildren: () =>
      import('./pages/coupon-form-page').then((m) => m.CouponFormPageModule),
  },
  {
    path: 'payment-type',
    loadChildren: () =>
      import('./pages/payment-type-page').then((m) => m.PaymentTypePageModule),
  },
  {
    path: 'cryptos',
    loadChildren: () =>
      import('./pages/crypto-list-page').then((m) => m.CryptoListPageModule),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./pages/payment-page').then((m) => m.PaymentPageModule),
  },
  {
    path: 'wallets',
    loadChildren: () =>
      import('./pages/wallet-integrations-page').then(
        (m) => m.WalletIntegrationsPageModule
      ),
  },
  {
    path: 'processing',
    loadChildren: () =>
      import('./pages/processing-payment-page').then(
        (m) => m.ProcessingPaymentPageModule
      ),
  },
  {
    path: 'expired',
    loadChildren: () =>
      import('./pages/qr-code-expired-page').then(
        (m) => m.QrCodeExpiredPageModule
      ),
  },
  {
    path: 'order-expired',
    loadChildren: () =>
      import('./pages/order-expired-page').then(
        (m) => m.OrderExpiredPageModule
      ),
  },
  {
    path: 'done',
    loadChildren: () =>
      import('./pages/payment-done-page').then((m) => m.PaymentDonePageModule),
  },
  {
    path: PATH_HTTP_ERROR,
    loadChildren: () =>
      import('./pages/http-error-page').then((m) => m.HttpErrorPageModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/not-found-page').then((m) => m.NotFoundPageModule),
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
