import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FullscreenOverlayContainer,
  OverlayContainer,
  OverlayModule,
} from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';
import { SHOPPER_PAYMENT_PORTAL_VERSION as VERSION } from '@environment/variables';
import { BaseFriendlyErrorComponent } from '@shared/angular/modules/friendly-error-view/classes';
import { FriendlyErrorViewComponent } from '@shared/angular/modules/friendly-error-view/components';
import { HttpErrorsHandlerService } from '@shared/angular/modules/http-errors-handler/services';
import { FriendlyViewParams } from '@shared/entities/core/modules';

import { TestPaymentBannerModule } from '../../../components/common/test-payment-banner';
import { HeaderModule } from '../../../components/common/header';
import { FRIENDLY_ERROR_PARAMS } from '../constants';

@Component({
  standalone: true,
  selector: 'pbw-pay-friendly-error-handler',
  templateUrl: './friendly-error-handler.component.html',
  styleUrls: ['./friendly-error-handler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    OverlayModule,
    FriendlyErrorViewComponent,
    TestPaymentBannerModule,
    HeaderModule,
    TranslateModule,
  ],
  providers: [
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  ],
})
export class FriendlyErrorHandlerComponent
  extends BaseFriendlyErrorComponent
  implements AfterViewInit
{
  public readonly appVersion: string = VERSION;
  public readonly viewName: string = 'error';
  public readonly hasSecondBtn: boolean = false;
  public readonly hasTimer: boolean = false;

  constructor(
    ngZone: NgZone,
    cdr: ChangeDetectorRef,
    httpErrorHandlerService: HttpErrorsHandlerService,
    @Inject(FRIENDLY_ERROR_PARAMS) data: FriendlyViewParams
  ) {
    super(ngZone, cdr, httpErrorHandlerService);
    this.viewName = data.viewName;
    this.hasSecondBtn = data.hasSecondBtn;
    this.hasTimer = data.hasTimer;
  }

  public ngAfterViewInit(): void {
    if (this.hasTimer) {
      super.addTimer();
    }
  }
}
