import { Inject, Injectable } from '@angular/core';
import {
  PostCancelMessage,
  PostCloseMessage,
  PostDisableCloseBtnMessage,
  PostDoneMessage,
  PostEnableCloseBtnMessage,
  PostErrorMessage,
  PostHideCloseBtnMessage,
  PostMessage,
  PostProcessingPaymentMessage,
  PostShopperPaymentConfirmationMessage,
} from '@shared/entities/common/classes/post-message';
import { ConfirmingMsg, DoneMsg } from '@shared/entities/common/models/message';

import { WINDOW } from '../../constants';

@Injectable({ providedIn: 'root' })
export class ModalHandlerService {
  private readonly _parentUrl: string;

  constructor(@Inject(WINDOW) private readonly _window: Window) {
    this._parentUrl = _window.name || '*';
  }

  /**
   * Close window and notify that the shopper paid and close the window.
   */
  public shopperPaymentConfirmation(): void {
    const message: PostMessage = new PostShopperPaymentConfirmationMessage();
    this._window?.parent?.postMessage(message, this._parentUrl);
  }

  /**
   * Close window and notify to parent the event.
   */
  public cancel(): void {
    const message: PostMessage = new PostCancelMessage();
    this._window?.parent?.postMessage(message, this._parentUrl);
  }

  /**
   * Notify to parent the orderId.
   * @param {DoneMsg} DoneMsg details of the order generated.
   */
  public done(data: DoneMsg): void {
    const message: PostMessage = new PostDoneMessage(data);
    this._window?.parent?.postMessage(message, this._parentUrl);
  }

  /**
   * Close window and notify to parent the event.
   * @param {DoneMsg | null} DoneMsg details of the order generated.
   */
  public close(data: DoneMsg | null): void {
    const message: PostMessage = new PostCloseMessage(data);
    this._window?.parent?.postMessage(message, this._parentUrl);
  }

  /**
   * Notify to parent that an error was ocurred.
   * @param {<Data = unknown>} error exception details.
   */
  public error<Data = unknown>(error: Data): void {
    const message: PostMessage = new PostErrorMessage(error);
    this._window?.parent?.postMessage(message, this._parentUrl);
  }

  /**
   * Close window and notify to parent the event.
   */
  public hideCloseBtn(): void {
    const message: PostMessage = new PostHideCloseBtnMessage();
    this._window?.parent?.postMessage(message, this._parentUrl);
  }

  /**
   * Enable modal close button.
   */
  public enableCloseBtn(): void {
    const message: PostMessage = new PostEnableCloseBtnMessage();
    this._window?.parent?.postMessage(message, this._parentUrl);
  }

  /**
   * Disable modal close button.
   */
  public disableCloseBtn(): void {
    const message: PostMessage = new PostDisableCloseBtnMessage();
    this._window?.parent?.postMessage(message, this._parentUrl);
  }

  /**
   * Prevent the order cancelation event when payment is processing.
   * @param {ConfirmingMsg} confirmingMsg (optional), contains trx details.
   */
  public processingPayment(confirmingMsg?: ConfirmingMsg): void {
    const message: PostMessage = new PostProcessingPaymentMessage(
      confirmingMsg
    );
    this._window?.parent?.postMessage(message, this._parentUrl);
  }
}
