import { Injectable } from '@angular/core';
import { ConfirmingMsg, DoneMsg } from '@shared/entities/common/models/message';

import { ModalHandlerService } from '../../services/modal-handler';
import { SessionStorageService } from '../../services/storage';

@Injectable({ providedIn: 'root' })
export class ZenkipayEventsAction {
  constructor(
    private readonly _modalHandlerService: ModalHandlerService,
    private readonly _storageService: SessionStorageService
  ) {}

  public shopperPaymentConfirmation(): void {
    this._storageService.clear();
    this._modalHandlerService.shopperPaymentConfirmation();
  }

  public cancel(): void {
    this._storageService.clear();
    this._modalHandlerService.cancel();
  }

  public done(data: DoneMsg): void {
    this._modalHandlerService.done(data);
  }

  public close(data: DoneMsg | null): void {
    this._storageService.clear();
    this._modalHandlerService.close(data);
  }

  public error<Data = unknown>(error: Data): void {
    this._storageService.clear();
    this._modalHandlerService.error(error);
  }

  public hideCloseBtn(): void {
    this._modalHandlerService.hideCloseBtn();
  }

  public enableCloseBtn(): void {
    this._modalHandlerService.enableCloseBtn();
  }

  public disableCloseBtn(): void {
    this._modalHandlerService.disableCloseBtn();
  }

  public processingPayment(confirmingMsg?: ConfirmingMsg): void {
    this._modalHandlerService.processingPayment(confirmingMsg);
  }
}
