import { Inject, Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import {
  CURRENT_ENVIRONMENT,
  SHOPPER_PAYMENT_PORTAL_VERSION,
} from '@environment/variables';
import { DatadogConfig } from '@shared/entities/common/models/datadog';

import { DATADOG_CONFIG } from '../../../constants/providers';
import { DatadogUser } from '../models';
import { DatadogService } from './datadog.service';

@Injectable({ providedIn: 'root' })
export class DatadogMonitorService extends DatadogService {
  constructor(
    @Inject(DATADOG_CONFIG)
    {
      applicationId,
      clientToken,
      service,
      sessionReplaySampleRate,
    }: DatadogConfig
  ) {
    super();
    datadogRum.init({
      applicationId,
      clientToken,
      service,
      site: 'datadoghq.com',
      env: CURRENT_ENVIRONMENT,
      version: SHOPPER_PAYMENT_PORTAL_VERSION,
      sessionReplaySampleRate,
      sessionSampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      useCrossSiteSessionCookie: true,
      useSecureSessionCookie: true,
    });
  }

  public override startSessionReplayRecording(): void {
    datadogRum.startSessionReplayRecording();
  }

  public override stopSessionReplayRecording(): void {
    datadogRum.stopSessionReplayRecording();
  }

  public override setUser(user: DatadogUser): void {
    datadogRum.setUser(user);
  }

  public override addError(error: unknown, context?: object): void {
    datadogRum.addError(error, context);
  }

  public override addAction(name: string, context?: object | undefined): void {
    datadogRum.addAction(name, context);
  }
}
