import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SHOPPER_PAYMENT_PORTAL_VERSION as VERSION } from '@environment/variables';
import { AutoUnsubscribe } from '@shared/angular/decorators/auto-unsubscribe';
import { Observable, Subject } from 'rxjs';

import { CLOSE_PAYMENT_OPTIONS } from '../enums';

type ClosePaymentOptionsFormGroup = {
  option: AbstractControl;
};

@Component({
  selector: 'pbw-pay-close-payment-options',
  templateUrl: './close-payment-options.component.html',
  styleUrls: ['./close-payment-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe
export class ClosePaymentOptionsComponent {
  public readonly CLOSE_PAYMENT_OPTIONS: typeof CLOSE_PAYMENT_OPTIONS =
    CLOSE_PAYMENT_OPTIONS;

  public readonly appVersion: string = VERSION;

  public readonly form: FormGroup<ClosePaymentOptionsFormGroup> =
    new FormGroup<ClosePaymentOptionsFormGroup>({
      option: new FormControl(null, Validators.required),
    });
  public get optionSelected(): CLOSE_PAYMENT_OPTIONS {
    return this.form.controls.option.value;
  }
  public set optionSelected(optionSelected: CLOSE_PAYMENT_OPTIONS) {
    this.form.controls.option.setValue(optionSelected, { emitEvent: true });
  }

  private readonly _optionSelected$: Subject<CLOSE_PAYMENT_OPTIONS> =
    new Subject<CLOSE_PAYMENT_OPTIONS>();
  public readonly optionSelected$: Observable<CLOSE_PAYMENT_OPTIONS> =
    this._optionSelected$.asObservable();

  public back(): void {
    const option: CLOSE_PAYMENT_OPTIONS = CLOSE_PAYMENT_OPTIONS.AWAITING_HERE;
    this._optionSelected$.next(option);
    this._optionSelected$.complete();
  }

  public submit(): void {
    if (this.form.invalid) return;
    const option: CLOSE_PAYMENT_OPTIONS = this.optionSelected;
    this._optionSelected$.next(option);
    this._optionSelected$.complete();
  }
}
