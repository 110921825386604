import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';

import { DatadogService } from '../services';

type HtpRequestDetails = {
  method: string;
  url: string;
  body?: unknown;
  status?: number;
  params?: Record<string, string>;
  response?: unknown;
};

@Injectable()
export class DatadogInterceptor implements HttpInterceptor {
  constructor(private readonly _datadogService: DatadogService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const method: string = request.method.toUpperCase();
    const url: string = request.url;
    const params: Record<string, string> | null = request.params.keys().length
      ? Array.from(
          new URLSearchParams(request.params.toString()).entries()
        ).reduce(
          (
            acc: Record<string, string>,
            [key, value]: [string, string]
          ): Record<string, string> => {
            acc[key] = value;
            return acc;
          },
          {}
        )
      : null;
    const body: unknown = request.body;
    const details: HtpRequestDetails = {
      method,
      url,
      body,
      ...(params && { params }),
    };

    return next.handle(request).pipe(
      tap((httpEvent: HttpEvent<unknown>): void => {
        if (httpEvent.type !== HttpEventType.Response) return;

        const name = `[${method}] ${url}`;
        details.status = httpEvent.status;
        httpEvent.body && (details.response = httpEvent.body);
        this._datadogService.addAction(name, details);
      }),
      catchError((error: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
        details.status = error.status;
        this._datadogService.addError(error, details);
        return throwError((): HttpErrorResponse => error) as Observable<
          HttpEvent<unknown>
        >;
      })
    );
  }
}
