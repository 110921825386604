<ui-kit-header
  [showBigLogo]="showBigLogo"
  [showAmount]="showAmount"
  [showDiscount]="showDiscount"
  [amount]="amount"
  [currency]="currency"
  [finalAmount]="finalAmount"
  [isTestPayment]="isTestPayment$ | async"
  [isDiscountActive]="isDiscountActive"
  [isDiscountChanged]="isDiscountChanged"
  [discount]="discount"
></ui-kit-header>
